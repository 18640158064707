import {
  FoldersContainer,
  FoldersRow,
  MainButton,
  MilestoneContainer,
  MilestoneDescription,
  SingleFolderFourth,
  TallFiltersCont,
} from "../../Styles";
import { CreateLockButton, FolderTab2024, FoldersContainer2024, ModalErrorCont, ModalSuccessCont } from "../../Styles2024";
import ChoiceCard from "../../components/ChoiceCard";
import CurrentResultCard from "../../components/CurrentResultCard";
import DaoNftCard from "../../components/DaoNftCard";
import {
  AddButton,
  ProposalChoices,
  RemoveButton,
  SubmitButton,
} from "../../components/LeftDiv";
import LoadingDiv from "../../components/LoadingDiv";
import { defaultSpinnerDuration, defaultTxSettings, v2DAOContract } from "../../data/config";
import { V2_PROPOSAL_STATUSES, error_svg, success_svg } from "../../data/constants";
import { SettingsCategoryTopWrapper, SettingsCategoryWrapper } from "../../data/css/SettingsStyles";
import {
  getPrecisionFromAsset,
  getQuantityFromAsset,
} from "../../data/functions/pool_functions";
import { FoldersRowCentered, LockAmountInput } from "../locker/LockStyles";
import { showCautionMessage } from "../token_tracker/tracker_functions";
import {
  Table,
  TableData,
  TableHeader,
  TableRow,
} from "./DAOStylesV2";
import { formatDistanceToNow, fromUnixTime, isPast } from 'date-fns';

export const formatEndTime = (endTime) => {
  const endDate = fromUnixTime(endTime);
  const prefix = isPast(endDate) ? 'Ended' : 'Ends in';
  const timeDistance = formatDistanceToNow(endDate);
  return isPast(endDate) ? `${prefix} ${timeDistance} ago` : `${prefix} ${timeDistance}`;
};

export const showProposalOutcomeText = (endTime, outcome) => {
  if(outcome == 0){
    const endDate = fromUnixTime(endTime);
    const timeDistance = formatDistanceToNow(endDate);
    if(isPast(endDate)){
      return "Awaiting Finalization"
    }

    //return isPast(endDate) ? "Awaiting Finalization" : "Finalized";
  }

  return V2_PROPOSAL_STATUSES[
      V2_PROPOSAL_STATUSES.findIndex(
        (attributeIndex) =>
          attributeIndex.value === outcome
      )
    ].display_text
   
};

const handleChoiceAdd = (setChoicesList, choicesList) => {
  setChoicesList([...choicesList, { choice: "" }]);
};

const handleChoiceRemove = (setChoicesList, choicesList, index) => {
  const list = [...choicesList];
  list.splice(index, 1);
  setChoicesList(list);
};

const handleChoiceChange = (e, index, choicesList, setChoicesList) => {
  const { name, value } = e.target;
  const list = [...choicesList];
  list[index][name] = value;
  setChoicesList(list);
};

const handleReceiverChange = (e, index, tokenReceivers, setTokenReceivers) => {
  const { name, value } = e.target;
  const list = [...tokenReceivers];

  if(name == "wax_account" || name == "contract"){
    list[index][name] = value.toLowerCase();
  } else if(name == "token_name"){
    list[index][name] = value.toUpperCase();
  } else {
    list[index][name] = value;
  }

  setTokenReceivers(list);
};

const handleReceiverAssetIDsChange = (
  e,
  index,
  tokenReceivers,
  setTokenReceivers
) => {
  const { name, value } = e.target;
  const list = [...tokenReceivers];
  list[index][name] = value.replace(/\s/g, "");
  setTokenReceivers(list);
};

const handleTokenReceiverAdd = (setTokenReceivers, tokenReceivers) => {
  setTokenReceivers([
    ...tokenReceivers,
    {
      wax_account: "",
      token_name: "",
      token_precision: "",
      contract: "",
      quantity: "",
    },
  ]);
};

const handleTokenReceiverRemove = (
  setTokenReceivers,
  tokenReceivers,
  index
) => {
  const list = [...tokenReceivers];
  list.splice(index, 1);
  setTokenReceivers(list);
};

const handleNftReceiverAdd = (setNftReceivers, nftReceivers) => {
  setNftReceivers([...nftReceivers, { wax_account: "", asset_ids: "" }]);
};

const handleNftReceiverRemove = (setNftReceivers, nftReceivers, index) => {
  const list = [...nftReceivers];
  list.splice(index, 1);
  setNftReceivers(list);
};

export const showV2ProposalForm = (
  daoData,
  title,
  setTitle,
  description,
  setDescription,
  proposalType,
  setProposalType,
  choicesList,
  setChoicesList,
  tokenReceivers,
  setTokenReceivers,
  nftReceivers,
  setNftReceivers,
  proofAssets,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {

  return (
    <span>
      <SettingsCategoryWrapper>
        <SettingsCategoryTopWrapper>
          Proposal Details
        </SettingsCategoryTopWrapper>
        <LockAmountInput wide={true}>
          <div className="w-100 text-center mb-3">
      <h3>Title</h3>
      <input
        placeholder="e.g. Proposal 69"
        maxLength={100}
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />
      <br />
      <br />

      <h3>Description</h3>
      <textarea
        rows={10}
        placeholder="Give an explanation of your proposal"
        maxLength={1500}
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <br />
      <br />

      <h3>Proposal Type</h3>
      <select
        onChange={(e) => {
          setProposalType(e.target.value);
        }}
      >
        <option value="" hidden>
          Choose
        </option>
        <option value={0}>Most Votes Wins</option>
        <option value={1}>Ranked Choice</option>
        <option value={2}>Transfer Tokens</option>
        <option value={3}>Transfer NFTs</option>
        <option value={4}>Yes/No/Abstain</option>
      </select>
      <br />
      <br />

      {/* most votes wins or ranked choice */}
      {(proposalType == 0 || proposalType == 1) &&
        showChoiceForm(choicesList, setChoicesList)}

      {/* token transfer */}
      {proposalType == 2 &&
        showTransferTokensForm(tokenReceivers, setTokenReceivers)}

      {/* NFT transfer */}
      {proposalType == 3 && showTransferNftsForm(nftReceivers, setNftReceivers)}

      {/* showYesNoAbstainForm (nothing to show) */}
      </div>
      </LockAmountInput>
      </SettingsCategoryWrapper>

      <div className="text-center w-100 justify-center">
        <CreateLockButton
          onClick={() => {
            submitV2Proposal(
              daoData,
              title,
              description,
              proposalType,
              choicesList,
              tokenReceivers,
              nftReceivers,
              proofAssets,
              setEnterModalText,
              setLoadingDisplay,
              setEnterButtonsDisplay,
              setEnterModalDisplay,
              wharfSession
            );
          }}
        >
          Submit Proposal
        </CreateLockButton>
      </div>
    </span>
  );
};

const showChoiceForm = (choicesList, setChoicesList) => {
  return (
    <span>
        <h2>Choices</h2>

      <br />
 

      {choicesList.map((singleChoice, index) => (
        <div key={index} className="w-100 text-center justify-center">
          <ProposalChoices
            placeholder={`Option ${index}`}
            rows={4}
            name="choice"
            id="choice"
            value={singleChoice.choice}
            onChange={(e) =>
              handleChoiceChange(e, index, choicesList, setChoicesList)
            }
          />

          {choicesList.length > 1 && (
            <RemoveButton
              onClick={() => {
                handleChoiceRemove(setChoicesList, choicesList, index);
              }}
            >
              &nbsp; - &nbsp;
            </RemoveButton>
          )}

          {choicesList.length - 1 === index && choicesList.length < 4 && (
            <span>
              <br />
              <AddButton
                onClick={() => {
                  handleChoiceAdd(setChoicesList, choicesList);
                }}
              >
                &nbsp; + &nbsp;
              </AddButton>
            </span>
          )}
        </div>
      ))}
    </span>
  );
};

const showTransferTokensForm = (tokenReceivers, setTokenReceivers) => {
  return (
    <span>
        <h2>Receivers</h2>
      <br />


      {tokenReceivers.map((singleReceiver, index) => (
        <div key={index} className="w-100 text-center justify-center">
          <h3>WAX Account</h3>
          <input
            placeholder={`e.g. bob.wam`}
            maxLength={13}
            name="wax_account"
            value={singleReceiver.wax_account}
            onChange={(e) =>
              handleReceiverChange(e, index, tokenReceivers, setTokenReceivers)
            }
          />
          <br />
          <br />

          <h3>Token Name</h3>
          <input
            placeholder={`e.g. WAX`}
            maxLength={7}
            name="token_name"
            value={singleReceiver.token_name}
            onChange={(e) =>
              handleReceiverChange(e, index, tokenReceivers, setTokenReceivers)
            }
          />
          <br />
          <br />

          <h3>Token Precision</h3>
          <input
            placeholder={`e.g. 8`}
            name="token_precision"
            value={singleReceiver.token_precision}
            onChange={(e) =>
              handleReceiverChange(e, index, tokenReceivers, setTokenReceivers)
            }
          />
          <br />
          <br />

          <h3>Token Contract</h3>
          <input
            placeholder={`e.g. eosio.token`}
            name="contract"
            value={singleReceiver.contract}
            maxLength={13}
            onChange={(e) =>
              handleReceiverChange(e, index, tokenReceivers, setTokenReceivers)
            }
          />
          <br />
          <br />

          <h3>Quantity</h3>
          <input
            placeholder={`e.g. 123.4`}
            name="quantity"
            value={singleReceiver.quantity}
            onChange={(e) =>
              handleReceiverChange(e, index, tokenReceivers, setTokenReceivers)
            }
          />
          <br />
          <br />

          {tokenReceivers.length > 1 && (
            <RemoveButton
              onClick={() => {
                handleTokenReceiverRemove(
                  setTokenReceivers,
                  tokenReceivers,
                  index
                );
              }}
            >
              &nbsp; - &nbsp;
            </RemoveButton>
          )}

          {tokenReceivers.length - 1 === index && tokenReceivers.length < 4 && (
            <span>
              <br />
              <AddButton
                onClick={() => {
                  handleTokenReceiverAdd(setTokenReceivers, tokenReceivers);
                }}
              >
                &nbsp; + &nbsp;
              </AddButton>
            </span>
          )}
        </div>
      ))}
    </span>
  );
};

const showTransferNftsForm = (nftReceivers, setNftReceivers) => {
  return (
    <span>
        <h2>Receivers</h2>
      <br />

      {nftReceivers.map((singleReceiver, index) => (
        <div key={index} className="w-100 text-center justify-center">
          <h3>WAX Account</h3>
          <input
            placeholder={`e.g. bob.wam`}
            name="wax_account"
            maxLength={13}
            value={singleReceiver.wax_account}
            onChange={(e) =>
              handleReceiverChange(e, index, nftReceivers, setNftReceivers)
            }
          />
          <br />
          <br />

          <h3>Asset IDs</h3>
          <textarea
            placeholder={`comma separated`}
            rows={4}
            name="asset_ids"
            value={singleReceiver.asset_ids}
            onChange={(e) =>
              handleReceiverAssetIDsChange(
                e,
                index,
                nftReceivers,
                setNftReceivers
              )
            }
          />
          <br />
          <br />

          {nftReceivers.length > 1 && (
            <RemoveButton
              onClick={() => {
                handleNftReceiverRemove(setNftReceivers, nftReceivers, index);
              }}
            >
              &nbsp; - &nbsp;
            </RemoveButton>
          )}

          {nftReceivers.length - 1 === index && nftReceivers.length < 4 && (
            <span>
              <br />
              <AddButton
                onClick={() => {
                  handleNftReceiverAdd(setNftReceivers, nftReceivers);
                }}
              >
                &nbsp; + &nbsp;
              </AddButton>
            </span>
          )}
        </div>
      ))}
    </span>
  );
};

const submitV2Proposal = async (
  daoData,
  title,
  description,
  proposalType,
  choicesList,
  tokenReceivers,
  nftReceivers,
  proofAssets,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  wharfSession
) => {
  const finalChoices = finalizeChoicesList(proposalType, choicesList);

  const finalTokenReceivers = finalizeTokenReceivers(
    proposalType,
    tokenReceivers
  );

  const finalNftReceivers = finalizeNftReceivers(proposalType, nftReceivers);

  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  if(localStorage.getItem("wharf--session") == null){
      setEnterModalText(
          "You are not logged in. Click the wallet icon in the top menu"
          );
          return;        
  }

  const session = wharfSession

  let depositAction1 = {
    account: v2DAOContract,
    name: "announcedepo",
    authorization: [session.permissionLevel],
    data: {
      user: session.actor,
    },
  };
  let depositAction2 = {
    account: "eosio.token",
    name: "transfer",
    authorization: [session.permissionLevel],
    data: {
      from: session.actor,
      to: v2DAOContract,
      quantity: daoData.proposal_cost,
      memo: "|proposal_payment|",
    },
  };

    const proposeAction = {
      account: v2DAOContract,
      name: "newproposal",
      authorization: [session.permissionLevel],
      data: {
        user: session.actor,
        dao: daoData.daoname,
        choices: finalChoices,
        proposal_type: proposalType,
        title: title,
        description: description,
        token_receivers: finalTokenReceivers,
        nft_receivers: finalNftReceivers,
        proof_asset_ids: proofAssets,
      },
    };

    let actions = getQuantityFromAsset(daoData.proposal_cost) > 0
    ? [depositAction1, depositAction2, proposeAction]
    : proposeAction;


    try {
      const result = await session.transact({ actions: actions }, defaultTxSettings);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(<span>
          <ModalSuccessCont>
          {success_svg}
          </ModalSuccessCont>
          <span>
          Your proposal has been created!
          </span>
        </span>)   
        setLoadingDisplay("none");
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log('ERROR: ', e);
      setEnterModalText(<span>
        <ModalErrorCont>
        {error_svg}
        </ModalErrorCont>
        {e.message}        
      </span>);
      setEnterModalDisplay("");
      setLoadingDisplay("none")
    }




    const result = await session.transact({ actions: actions }, defaultTxSettings ).catch((e) => {
      console.log('error caught in transact', e)
          setEnterModalText("")
          setEnterModalDisplay("hidden")
      })

      setEnterModalText("Your proposal was created")    

};

const finalizeChoicesList = (proposalType, choicesList) => {
  if (proposalType == 0 || proposalType == 1) {
    if (choicesList.length >= 2) {
      let processedChoices = choicesList.map((item, index) => {
        return {
          choice: index,
          description: item.choice,
          total_votes: 0,
        };
      });
      return processedChoices;
    } else {
      //handle the case where there aren't enough items in choices list
      //set enter modal text and stop execution
    }
  } else if (proposalType == 2 || proposalType == 3) {
    //choices are yes or no
    return [
      { choice: 0, description: "Yes", total_votes: 0 },
      { choice: 1, description: "No", total_votes: 0 },
    ];
  } else if (proposalType == 4) {
    //choices are yes/no/abstain
    return [
      { choice: 0, description: "Yes", total_votes: 0 },
      { choice: 1, description: "No", total_votes: 0 },
      { choice: 2, description: "Abstain", total_votes: 0 },
    ];
  }
};

const finalizeTokenReceivers = (proposalType, tokenReceivers) => {
  //this only applies to type 2
  if (proposalType != 2) {
    return [];
  }
  if (tokenReceivers.length >= 1) {
    let processedReceivers = tokenReceivers.map((item, index) => {
      return {
        wax_account: item.wax_account,
        quantity: processQuantity(
          item.token_name,
          item.token_precision,
          item.quantity
        ),
        contract: item.contract,
      };
    });
    return processedReceivers;
  } else {
    //handle the case where there aren't enough items in choices list
    //set enter modal text and stop execution
  }
};

const finalizeNftReceivers = (proposalType, nftReceivers) => {
  //this only applies to type 3
  if (proposalType != 3) {
    return [];
  }
  if (nftReceivers.length >= 1) {
    let processedReceivers = nftReceivers.map((item, index) => {
      return {
        wax_account: item.wax_account,
        asset_ids: item.asset_ids.split(","),
      };
    });
    return processedReceivers;
  } else {
    //handle the case where there aren't enough items in choices list
    //set enter modal text and stop execution
  }
};

const processQuantity = (name, precision, amount) => {
  return Number(amount).toFixed(Number(precision)) + " " + name.toUpperCase();
};

export const showDAOTreasury = (
  dao_name,
  currentTreasurySection,
  setCurrentTreasurySection,
  daoTokens,
  getDaoTokenTreasury,
  tokensAreLoading,
  daoNfts,
  getDaoNftTreasury,
  daoNftsAreLoading,
  hasNextDaoNftPage,
  nsfwIsLoading,
  nsfwCollections
) => {
  return (
    <span>
      <FoldersContainer2024>
        <FoldersRowCentered>
          <FolderTab2024
            onClick={(e) => {
              setCurrentTreasurySection("Tokens");
            }}
            selected={currentTreasurySection == "Tokens" && true}
          >
            Tokens
          </FolderTab2024>
          <FolderTab2024
            onClick={(e) => {
              setCurrentTreasurySection("NFTs");
              getDaoNftTreasury(dao_name);
            }}
            selected={currentTreasurySection == "NFTs" && true}
          >
            NFTs
          </FolderTab2024>
        </FoldersRowCentered>
      </FoldersContainer2024>
      {currentTreasurySection == "Tokens" && daoTokens?.length > 0 && (
        <span>
          <br />
          <Table>
            <thead>
              <TableRow>
                <TableHeader>Balance</TableHeader>
                <TableHeader>Contract</TableHeader>
                <TableHeader>Precision</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {daoTokens.map((token, index) => (
                <TableRow key={index}>
                  <TableData>{token.balance}</TableData>
                  <TableData>{token.contract}</TableData>
                  <TableData>{getPrecisionFromAsset(token.balance)}</TableData>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </span>
      )}

      {currentTreasurySection == "Tokens" &&
        (daoTokens == null || daoTokens?.length == 0) && (
          <span>
            <br />
            This DAO has no tokens in its treasury
          </span>
        )}

      {currentTreasurySection == "NFTs" &&
        (daoNfts == null || daoNfts?.length == 0) && (
          <div className="w-100 text-center mt-3">
            {daoNftsAreLoading ? <LoadingDiv /> : 'This DAO has no NFTs in its treasury'}
          </div>
        )}

      {currentTreasurySection == "NFTs" &&
         daoNfts?.length >=1 && (
          <span>
          <div className="w-100 flex flex-wrap justify-center gap-2 mt-4">
            {daoNfts.map((nft, index) => (
              <DaoNftCard item={nft} nsfwIsLoading={nsfwIsLoading} nsfwCollections={nsfwCollections} 

              />
            ))}


          </div>
          {hasNextDaoNftPage() && (
              <div className="w-100 text-center justify-center mt-4">
                <MainButton
                  onClick={() => {
                    getDaoNftTreasury(dao_name, true);
                  }}
                >
                  Load More
                </MainButton>
              </div>
            )}             
          </span>
        )}
        
     
    </span>
  );
};


export const showChoices = (choices, selectedChoice, setSelectedChoice) => {
  return <div className="text-center justify-center w-100">
{choices?.map((item, index) => (
    <ChoiceCard key={index} choice={item} selectedChoice={selectedChoice} setSelectedChoice={setSelectedChoice} />
  ))}
  </div> 

}

export const showCurrentResults = (choices, total_votes) => {
  return <div className="text-center justify-center w-100">
{choices?.sort((a, b) => b.total_votes - a.total_votes).map((item, index) => (
    <CurrentResultCard choice={item} total_votes={total_votes} />
  ))}
  </div> 

}

export const showTransferWarning = (proposal_info) => {
  if(proposal_info.proposal_type == 2){//token transfer
    return <span>        
      
        {showCautionMessage('CAUTION: If this proposal passes, tokens will be transferred automatically from the DAO treasury to the following accounts:')}
        {proposal_info.token_receivers.map((account, index) => (
          <MilestoneContainer key={index}>
            <h3>{account.wax_account}</h3>
            <MilestoneDescription>
              {account.quantity}@{account.contract}
            </MilestoneDescription>
          </MilestoneContainer>
        ))}
        <br/><br/>
    </span>
  }
  else if(proposal_info.proposal_type == 3){//nft transfer
    return <span>        
        <div className="p-6 max-w-sm mx-auto bg-yellow-100 rounded-xl shadow-md flex items-center space-x-4">
            <div className="flex-shrink-0">
                <svg className="h-12 w-12 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M2.003 3.004L10 2l7.997 1.004v9.992L10 18l-7.997-1.004V3.004zM10 16H5V4h5v12zm0 2H5a1 1 0 01-1-1v-1h7v1a1 1 0 01-1 1zm-1-10a1 1 0 00-1-1H6a1 1 0 000 2h1a1 1 0 002 0zm0 2a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
            </div>
            <div>
                <div className="text-xl font-medium text-black">
                    Caution
                </div>
                <p className="text-gray-500">
                If this proposal passes, NFTs will be transferred automatically 
                from the DAO treasury to the following accounts:
                </p>
            </div>
        </div>

        {proposal_info.nft_receivers.map((account, index) => (
          <MilestoneContainer key={index}>
            <h3>{account.wax_account}</h3>
            <MilestoneDescription>
              {account.asset_ids.map((nft, nft_index) => (
                <h3 key={nft_index}>{nft}</h3>
              ))}
            </MilestoneDescription>
          </MilestoneContainer>
        ))}
        <br/><br/>
    </span>
  }  
  return;
}

export const finalizeV2Proposal = async (dao_name, ProposalID, setEnterModalText, setLoadingDisplay, 
  setEnterButtonsDisplay, setEnterModalDisplay, wharfSession) => 
  {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

      if(localStorage.getItem("wharf--session") == null){
        setEnterModalText(
            "You are not logged in. Click the wallet icon in the top menu"
            );
            return;        
    }

    const session = wharfSession
 
      const action = [{
          account: v2DAOContract,
          name: 'finalize',
          authorization: [session.permissionLevel],
        data: {
            user: session.actor,
            dao: dao_name,
            proposal_id: ProposalID,
        }
      }]
      
      try {
        const result = await session.transact({ actions: action }, defaultTxSettings);
        setLoadingDisplay("");
        setEnterModalText("Processing your transaction...");
        const timer = setTimeout(() => {
          setEnterModalText("The proposal has been finalized")   
          setLoadingDisplay("none");
        }, defaultSpinnerDuration);
        return () => clearTimeout(timer);
      } catch (e) {
        console.log('ERROR: ', e);
        setEnterModalText(e.message);
        setEnterModalDisplay("");
      }

}