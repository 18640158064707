import React from "react";
import { Helmet } from "react-helmet";
import { currentWebsiteURL } from "../data/config";
import google_analytics from "../data/google_analytics.png";
import dapp_radar from "../data/dapp_radar.png";
import google_search_console from "../data/google_search_console.png";
import { HeaderCont2024, PageBody2024, PageWrapper2024, theme2024 } from "../Styles2024";
import { DarkBgTextBox } from "../pages/utilities/PowerupStyles";

const AdTerms = () => {
  return (
    <div id="seo">
      <Helmet>
        <title>Ad Terms - WaxDAO</title>
        <meta
          name="description"
          content="Terms and conditions for advertising on WaxDAO"
        />
        <link rel="canonical" href={`${currentWebsiteURL}/ad-terms`} />
      </Helmet>

        <PageWrapper2024>
          <PageBody2024>
            <HeaderCont2024>
              <h2>Ad Terms And Conditions</h2>
            </HeaderCont2024>

            <DarkBgTextBox>
              <h2>How much does a banner ad cost?</h2>
              <br />
              <p>
                Currently the price is 50 WAX for a 24 hour slot, or{" "}
                <a href={`${currentWebsiteURL}/swap`} style={{color: theme2024.danger, fontWeight: 600}} target="none">
                  5,000 WAXDAO</a> (both tokens are accepted).
              </p>
            </DarkBgTextBox>

            <DarkBgTextBox>
              <h2>Where will the image be shown?</h2>
              <br />
              <p>
                Your banner will be shown on individual{" "}
                <a href={`${currentWebsiteURL}/tokens`}>token pages</a>, the
                market page, the blends page, individual drop pages, the NFT
                farms page, and the token staking pools page.
              </p>
            </DarkBgTextBox>

            <DarkBgTextBox>
              <h2>What size should the image be?</h2>
              <br />
              <p>
                The image should be 500px wide by 150px tall.
              </p>
            </DarkBgTextBox>

            <DarkBgTextBox>
              <h2>What file formats are accepted?</h2>
              <br />
              <p>
                Use a .png, .jpg or .gif file.
              </p>
            </DarkBgTextBox>

            <DarkBgTextBox>
              <h2>Do I need to use IPFS?</h2>
              <br />
              <p>
                Yes, we do not allow file uploads on WaxDAO. IPFS images only.
              </p>
            </DarkBgTextBox>

            <DarkBgTextBox>
              <h2>How many sales/clicks will I get by advertising?</h2>
              <br />
              <p>
                WaxDAO makes 0 guarantees about any conversion rates. Here are
                some of our stats for December 2022:
                <br />
                <br />
                <h3>Google Search Console:</h3>
                <img src={google_search_console} />
                <br />
                <br />
                <h3>DappRadar:</h3>
                <img src={dapp_radar} />
                <br />
                <br />
                <h3>Google Analytics:</h3>
                <img src={google_analytics} />
                <br />
                <br />
                Do with this info as you please, we make no guarantees. We are
                constantly growing and building our userbase though, so
                personally I'd say it's a bargain.
              </p>
            </DarkBgTextBox>

            <DarkBgTextBox>
              <h2>
                What is allowed, can I post nudity and hate speech in my banner
                ad?
              </h2>
              <br />
              <p>
                Semi-provocative stuff is ok, but full on nudity will be
                removed. As far as language goes, we aren't here to police
                anyone - but use your brain please. WaxDAO reserves the right to
                remove any content for any reason, with or without a refund.
                <br />
                <br />
                If you submit a URL that links to anything malicious (malware
                etc), your banner ad will be removed and you will not be
                refunded.
              </p>
            </DarkBgTextBox>

            <DarkBgTextBox>
              <h2>Is WaxDAO partnered with the project I saw in the banner?</h2>
              <br />
              <p>
                No, banner ads are submitted by users in exchange for a payment.
                WaxDAO is not partnered with anyone unless otherwise stated by
                WaxDAO. We hate the word partner just as much as anyone else
                does.
              </p>
            </DarkBgTextBox>

            <DarkBgTextBox>
              <h2>I cant find the advertising form!</h2>
              <br />
              <p>
                Visit the{" "}
                <a href={`${currentWebsiteURL}/advertise`}>Advertise page</a> to
                create your ad banner.
              </p>
            </DarkBgTextBox>
          </PageBody2024>
        </PageWrapper2024>
    </div>
  );
};

export default AdTerms;
