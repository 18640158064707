import React, { useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {Helmet} from "react-helmet";
import { CreateFarmIPFS, GameButton, Modal, ModalContent, Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from '../../components/LeftDiv';
import { mainnetWebsiteURL } from '../../data/config';
import { MainButton, NewListingBody, NewListingCont, SmallButton, TallFiltersCont, TallFiltersInnerCont } from '../../Styles';
import { WOJAK_SECONDARY_LINK, resizerPrefix, resizerSuffix } from '../../data/constants';
import { createPool, getTokenHelp } from './create_pool_functions';
import DatePicker from "react-datepicker";
import { handleCalendarChange } from '../../data/functions/pack_functions';



const CreatePool = () => {
  
  const {   
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    wharfSession
  } = useStateContext();
 
const [farmName, setFarmName] = useState("");
const [farmLogo, setFarmLogo] = useState("");
const [decimals, setDecimals] = useState(0);
const [tokenToStake, setTokenToStake] = useState("");
const [tokenContract, setTokenContract] = useState("");
const [rewardToken, setRewardToken] = useState("");
const [rewardContract, setRewardContract] = useState("");
const [rewardDecimals, setRewardDecimals] = useState(0);
const [timestamp, setTimestamp] = useState(Date.now() / 1000);
const [paymentMethod, setPaymentMethod] = useState("");
const [assetToBurn, setAssetToBurn] = useState("");
const [stakingAmount, setStakingAmount] = useState(0);
const [stakingPeriod, setStakingPeriod] = useState(1);
const [stakingSeconds, setStakingSeconds] = useState(86400);

  return (
    <div id="seo">
    <Helmet>
    <title>Create Staking Pool - WaxDAO</title>
    <meta name="description" content="Create your own staking pool on Wax blockchain, using any Wax token." />
    <link rel="canonical" href={`${mainnetWebsiteURL}/create-pool`} />
    </Helmet>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
    </Modal>

<NewListingCont>
  <NewListingBody>

<TallFiltersCont>
  <h2>Staking Pool Creator</h2>
  <br/>
  <TallFiltersInnerCont>
    You can use this tool to create a single-sided staking pool, or you can create an LP farm where you reward users for staking an LP token.
  </TallFiltersInnerCont>
</TallFiltersCont>


<TallFiltersCont>
  <h2>Pool Details</h2>
  <br/>
  <TallFiltersInnerCont>
    <h3>Pool Name</h3>
    <input 
      maxLength={12}
      placeholder="e.g. mytokenpool"
      value={farmName}
      onChange={(e) => {
        setFarmName(e.target.value.toLowerCase());
        }}    
    />
    <br/><br/>

    <h3>Pool Logo (IPFS)</h3>
    <input 
      placeholder='e.g. Qmabc...'
      maxLength={150}
      value={farmLogo}
      onChange={(e) => {
        setFarmLogo(e.target.value);
      }}    
    />
    <br/><br/>

      {farmLogo != null && farmLogo != '' && farmLogo.length > 10 && (
        <span>
          <CreateFarmIPFS src={`${resizerPrefix}${farmLogo}${resizerSuffix}`} />
          <br/><br/>
          </span>
        )}
    

    <h3>Pool Expiration Date</h3>

    <DatePicker
      showTimeSelect
      timeIntervals={15}
      selected={new Date(timestamp * 1000)}
      onChange={(e) => {
        handleCalendarChange(e, setTimestamp);
      }}
    />
    <br/><br/>

    <h3>Minimum Staking Period (Days)</h3>
    <input 
      value={stakingPeriod}
      onChange={(e) => {
          setStakingPeriod(e.target.value);
          setStakingSeconds(e.target.value * 86400);
      }}    
    />
    <br/>
    <span className='text-sm italic'>* Must be at least 1 day</span>


  </TallFiltersInnerCont>
</TallFiltersCont>

<TallFiltersCont>
      <div className='pl-3 pr-3'>
    <span className="font-bold">Warning:</span> Entering incorrect token details will result in a pool that doesn't work, and WaxDAO will not be able 
    to help you. <br/>
    Make 100% sure that you have the correct symbol, precision and contract for both the staking token, and the reward token.
    <br/>
    WaxDAO will not be held responsible for user errors.
    </div>  
      </TallFiltersCont>  


<TallFiltersCont>
  <h2>Token To Stake &nbsp;&nbsp;<SmallButton onClick={() => getTokenHelp(setEnterModalDisplay, setEnterModalText)}>Help!</SmallButton></h2>
  <br/>
  <TallFiltersInnerCont>
  <h3>Symbol</h3>
  <input 
      maxLength={7}
      value={tokenToStake}
      placeholder="e.g. WAX"
      onChange={(e) => {
        setTokenToStake(e.target.value.toUpperCase());
      }}  
  />
  <br/><br/>

  <h3>Precision</h3>
  <input 
    value={decimals}
    onChange={(e) => {
        setDecimals(e.target.value)
    }}
  />
  <br/><br/>

  <h3>Contract</h3>
  <input 
      maxLength={12}
      placeholder="e.g. eosio.token"
      value={tokenContract}
      onChange={(e) => {
        setTokenContract(e.target.value.toLowerCase());
      }}  
  />
  <br/><br/>

  <h3>Minimum Staking Amount</h3>
  <input 
      value={stakingAmount}
      onChange={(e) => {
          setStakingAmount(e.target.value);
      }}
  />
  <br/><br/>

  </TallFiltersInnerCont>
</TallFiltersCont>

<TallFiltersCont>
  <h2>Reward Token &nbsp;&nbsp;<SmallButton onClick={() => getTokenHelp(setEnterModalDisplay, setEnterModalText)}>Help!</SmallButton></h2>
  <br/>
  <TallFiltersInnerCont>
    <h3>Symbol</h3>
    <input 
      maxLength={7}
      placeholder="e.g. WAX"
      value={rewardToken}
      onChange={(e) => {
        setRewardToken(e.target.value.toUpperCase());
      }}    
    />
    <br/><br/>

    <h3>Precision</h3>
    <input 
      value={rewardDecimals}
      onChange={(e) => {
          setRewardDecimals(e.target.value)
      }}    
    />
    <br/><br/>

    <h3>Contract</h3>
    <input 
      maxLength={12}
      placeholder="e.g. eosio.token"
      value={rewardContract}
      onChange={(e) => {
        setRewardContract(e.target.value.toLowerCase());
      }}    
    />

  </TallFiltersInnerCont>
</TallFiltersCont>


<TallFiltersCont>
  <h2>Payment Details</h2>
  <br/>
  <TallFiltersInnerCont>
      <h3>Payment Method</h3>
      <select         
        onChange={(e) => {setPaymentMethod(e.target.value)}}
        >
      <option value="" hidden>
        Choose
      </option>
      <option value="Pay 250 Wax">Pay 250 Wax</option>
      <option value="Pay 25,000 WAXDAO">Pay 25,000 WAXDAO</option>
      <option value="Burn 1 Wojak NFT">Burn 1 Wojak NFT</option>
      </select>     
      <br/><br/> 
    
    <span className={paymentMethod != "Burn 1 Wojak NFT" && "hidden"}>
    <h3>Asset ID To Burn</h3>
    <input 
      placeholder='e.g. 123469420'
      value={assetToBurn}
      onChange={(e) => {
      setAssetToBurn(e.target.value);
      }}    
    />
    <br/>
    <span className='text-sm italic'>* If you don't have any Wojak NFTs, you can buy one <a href={WOJAK_SECONDARY_LINK} target='none'>on secondary</a></span>
    <br/><br/>
    </span>

    <MainButton onClick={() =>
      createPool(paymentMethod, farmName, farmLogo, tokenToStake, decimals, tokenContract, rewardToken, rewardDecimals, rewardContract, stakingSeconds, timestamp, stakingAmount, assetToBurn, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay, wharfSession)
    }>
      Create Pool
    </MainButton>
  </TallFiltersInnerCont>
</TallFiltersCont>




  </NewListingBody>
</NewListingCont>

    <br/><br/><br/>
    </div>
  )
}

export default CreatePool