import React from 'react';
import header_logo from '../data/header_logo.svg';
import { ColumnLinks, ColumnTitle, FooterColumn, FooterHref, FooterLeft, FooterLogo, FooterRight } from './LeftDiv';
import { currentWebsiteURL } from '../data/config';
import { FooterCont, SocialIconsDiv } from '../data/css/FooterStyles';
import { discord_svg, github_svg, telegram_svg, twitter_svg, youtube_svg } from '../data/svgs';



const Footer = () => {

  return (
    <FooterCont>
      <FooterLeft>
      <FooterLogo src={header_logo} />
      </FooterLeft>

      <FooterRight>
        <FooterColumn>
        <ColumnTitle>
        Creator Tools
        </ColumnTitle>
        <ColumnLinks>
        <FooterHref href={`${currentWebsiteURL}/request-listing`}>Listing Request</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/token-creator`}>Token Creator</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/create-blend`}>Super Blender</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/create-farm`}>NFT Farms</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/create-pool`}>Staking Pools</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/create-drop`}>NFT Drop Creator</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/v2/create-dao`}>DAO Creator</FooterHref><br/>

        
        </ColumnLinks>
        </FooterColumn>
        <FooterColumn>
        <ColumnTitle>
        User Tools
        </ColumnTitle>
        <ColumnLinks>
        <FooterHref href={`${currentWebsiteURL}/farms`}>Stake NFTs</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/pools`}>Stake Tokens</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/tokens`}>Token Explorer</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/drops`}>Buy NFTs</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/migrate`}>Swap Tokens</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/wallet`}>Wallet</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/v2/daos`}>DAOs</FooterHref><br/>
        </ColumnLinks>
        </FooterColumn>
        <FooterColumn>
        <ColumnTitle>
        Info
        </ColumnTitle>
        <ColumnLinks>
        <FooterHref href={`${currentWebsiteURL}/tokens/WAXDAO@token.waxdao`}>Token</FooterHref><br/>
        <FooterHref href="https://t.me/hoodpunks" target="none">Support</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/advertise`}>Advertise</FooterHref><br/>
        
        </ColumnLinks>
        </FooterColumn>

      </FooterRight>

      <SocialIconsDiv>
        {/* Telegram */}
        <a href="https://t.me/hoodpunks" target="none">
          {telegram_svg}
        </a>

      {/* Twitter */}
      <a href="https://x.com/waxdao_io" target="none">
        {twitter_svg}
      </a>

      {/* Github */}
      <a href="https://github.com/mdcryptonfts" target="none">
        {github_svg}
      </a>  

      {/* Discord */}
      <a href="https://discord.com/invite/xdvn5dkqvP" target="none">
        {discord_svg}
      </a>

      {/* YouTube */}
      <a href="https://www.youtube.com/@MikeDCrypto" target="none">
        {youtube_svg}
      </a> 
      </SocialIconsDiv>
    </FooterCont>
  )
}

export default Footer