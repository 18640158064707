import axios from "axios";
import { getCurrentApiList } from "../config";
import { DropCardImage, NsfwOverlay } from "../css/DropStyles";
import { STANDARD_NFT_IMAGE_WIDTH, ipfsPrefix, resizerPrefix, resizerSuffix } from "../constants";
import { WalletCardVideo } from "../css/WalletStyles";
import { hasAutoplayOn } from "../../pages/Settings";

export const getCustomersData = async (nameValue, setWhitelistStatus) => {
  try {
    const wlistResponse = await axios.post(
      `${getCurrentApiList}/v1/chain/get_table_rows`,
      {
        table: "whitelist",
        scope: "waxywojaknft",
        code: "waxywojaknft",
        limit: 1,
        lower_bound: nameValue,
        upper_bound: nameValue,
        json: true,
      }
    );
      
    if (wlistResponse.data.rows[0]?.username !== undefined) {
      setWhitelistStatus(true);
      localStorage.setItem("wlstatus", "true");
    }
  } catch (e) {
    console.log(e);
  }
};


export const buildCollectionUrl = (collection) => {
  let prefix = "https://wax.atomichub.io/explorer/collection/wax-mainnet/";
  return prefix + collection;
}

export const buildSchemaUrl = (collection, schema) => {
  let prefix = "https://wax.atomichub.io/explorer/schema/wax-mainnet/";
  return prefix + collection + "/" + schema;
}

export const buildTemplateUrl = (collection, template_id) => {
  let prefix = "https://wax.atomichub.io/explorer/template/wax-mainnet/";
  return prefix + collection + "/" + template_id;
}

export const buildNftImage = (data) => {
  const possibleKeys = ["video", "img", "image", "front", "picture", "pic", "ipfs", "front_image",
      "cover", "back", "backimg", "back_image"];

  const lowerCasedData = Object.keys(data).reduce((newData, key) => {
    newData[key.toLowerCase()] = data[key];
    return newData;
  }, {});

  for (let key of possibleKeys) {
    const val = lowerCasedData[key];
    if (val && val.length >= 5) {
        if (key === 'video') {
          if(hasAutoplayOn()){
            return (
              <WalletCardVideo autoPlay loop muted loading="lazy">
                <source src={buildIpfsUrl(val.trim(), STANDARD_NFT_IMAGE_WIDTH)} type="video/mp4" />
              </WalletCardVideo>
            );
          } else {
            return (
              <WalletCardVideo controls loop muted loading="lazy">
                <source src={buildIpfsUrl(val.trim(), STANDARD_NFT_IMAGE_WIDTH)} type="video/mp4" />
              </WalletCardVideo>
            );            
          }

        } else {
          return <DropCardImage src={buildIpfsUrl(val, STANDARD_NFT_IMAGE_WIDTH)} loading="lazy" />;
        }
      }
    }
};

export const buildNftImage2024 = (data, setIsLoading) => {
  const possibleKeys = ["img", "image", "front", "picture", "pic", "ipfs", "front_image",
      "cover", "back", "backimg", "back_image", "video"];

  const lowerCasedData = Object.keys(data).reduce((newData, key) => {
    newData[key.toLowerCase()] = data[key];
    return newData;
  }, {});

  for (let key of possibleKeys) {
    const val = lowerCasedData[key];
    if (val && val.length >= 5) {
         if(key !== "video") {
          if(!hasAutoplayOn()){
            return <img src={`${buildIpfsUrl(val, 310)}`} 
            onLoad={() => setIsLoading(false)}
            onError={() => setIsLoading(false)}
            loading="lazy" />;            
          } else {
            return <img src={buildIpfsUrl(val, STANDARD_NFT_IMAGE_WIDTH)} 
            onLoad={() => setIsLoading(false)}
            onError={() => setIsLoading(false)}
            loading="lazy" />;            
          }


        } else if (key === 'video') {

            if(!hasAutoplayOn()){
              return <img src={`${buildIpfsUrl(val, STANDARD_NFT_IMAGE_WIDTH)}`} 
              onLoad={() => setIsLoading(false)}
              onError={() => setIsLoading(false)}
              loading="lazy" />;              
            }
            else{
              return (
                <video controls loop muted 
                onLoadedMetadata={() => setIsLoading(false)}
                onError={() => setIsLoading(false)}              
                loading="lazy">
                  <source src={buildIpfsUrl(val.trim(), STANDARD_NFT_IMAGE_WIDTH)} type="video/mp4" />
                </video>
              );           
          }
        } 
      }
    }
};

export const buildIpfsUrl = (hash, width) => {
  if (hash.includes('wrskn.io')
  ) {
    return hash;
  }
  
  const urlRegex = /^(https?:\/\/[^/]+)?\/ipfs\//;

  let finalHash = hash;

  if(hash.trim().includes(' ')){
    //console.log("ipfs hash (1): " + `${ipfsPrefix}${hash.trim()}`)
    finalHash = finalHash.replace(' ', '');
    //return `${ipfsPrefix}${hash.trim().replace(' ', '')}`;
  }


  const cleanedHash = hash.replace(urlRegex, '');

  if(cleanedHash.indexOf('https://') > -1 || cleanedHash.indexOf('http://') > -1){
    return cleanedHash
  }

  if(cleanedHash.indexOf('/') > -1){
    //return `${ipfsPrefix}${cleanedHash}`
  }

  const separator = hash.includes('?') ? '&' : '?';
  const widthParam = width ? `${separator}width=${width}` : '';
  const autoplayParam = !hasAutoplayOn() ? '&autoplay=off' : '';

  //console.log("ipfs hash (2): " + `${resizerPrefix}${cleanedHash}${widthParam}`)
  return `${resizerPrefix}${cleanedHash}${widthParam}${autoplayParam}`;
}

export const buildMyCollectionsImg = (data) => {
  const possibleKeys = ["video", "img", "image", "front", "picture", "pic", "ipfs", "front_image",
      "cover", "back", "backimg", "back_image"];

  const lowerCasedData = Object.keys(data).reduce((newData, key) => {
    newData[key.toLowerCase()] = data[key];
    return newData;
  }, {});

  const logo512x512 = lowerCasedData['data']?.['images']?.['logo_512x512'];
  if (logo512x512 && logo512x512.length >= 5) {
    return <img src={buildIpfsUrl(logo512x512, STANDARD_NFT_IMAGE_WIDTH)} />;
  }

  for (let key of possibleKeys) {
    const val = lowerCasedData[key];
    if (val && val.length >= 5) {
      return <img src={buildIpfsUrl(val, STANDARD_NFT_IMAGE_WIDTH)} />;
    }
  }

  return <img src="https://logos.waxdao.io/default-logo.png" />;
};

export const collectionIsNsfw = (collection, nsfwCollections) => {
  if(nsfwCollections.includes(collection)){
    return true
  }
  return false
}

export const showNsfwOverlay = () => {
  return <NsfwOverlay>
    NSFW
  </NsfwOverlay>

}