import styled from 'styled-components'
import { testTheme, theme } from '../Styles'
import { theme2024 } from '../Styles2024'


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

//COLORS
//#c30000
//#47158e
//#dc0bb6

export const HomePageStars = styled.img`

    width: 100%;
    max-width: 100%;
    height: auto;

`


export const Fuck = styled.span`

.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
    height: 40px;
    font-size: 20px;
}

`

export const GameButton = styled.button`

width: 200px;
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    border: 1px solid white;
    margin-top: 1em;

    @media (max-width: 500px) {
        font-size: 14px;
    }

    :hover{
        color: black;
        background-color: white;
    }
`


export const ModalNFT = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width: 150px;
    max-width: 150px;
    padding:15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 12px;
    word-wrap: break-word;
    

`

export const BlendTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
`

export const ShowDataButton = styled.button`

    font-size: 12px;
    font-weight: 500;
    //color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    padding: 5px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 10px;
    margin-top:10px;

    :hover{
        color: #131313;
        background-color: #fa872d;
    }

`
export const ModalNFTCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
   
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    height: 300px;
    overflow-x: scroll;
    box-shadow: 0px 0px 3px 0.1px #858383;
    background-color: #272626;

`


export const ModalNFTLogo = styled.img`
    width: 120px;
    max-width: 120px;
    height: 120px;
    max-height: 120px;
    border-radius: 5px; 
`

export const Modal = styled.div`
   
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15011;
   
    width: 600px;
    max-width: 90%;
    height: 600px;
    max-height: 70%;
    background-color: ${theme.mainDarker};
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 30px;
    box-shadow: 0px 0px 3px 1px whitesmoke;
    overflow-x: scroll;

    @media (max-width: 900px) {
        margin-top: 50px; 
    }
`


export const BlendModal = styled.div`
   
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 16000;
   
    width: 600px;
    max-width: 90%;
    height: 600px;
    max-height: 96%;
    background-color: #1c1c1c;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 30px;
    box-shadow: 0px 0px 5px 3px whitesmoke;
    overflow-x: scroll;

    @media (max-width: 900px) {
        margin-top: 50px; 
    }
    

`


export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15010;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background-color: #1c1c1c;
    opacity: 80%;
    
`


export const NewCloudButton = styled.button`
    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    background-color: #fc8931;
    margin-top: 1em;

    @media (max-width: 500px) {
        font-size: 14px;

    }
`

export const NewAnchorButton = styled.button`
    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    background-color: #263ec5;
    margin-top: 1em;

    @media (max-width: 500px) {
        font-size: 14px;

    }
`

export const GoToWalletPage = styled.a`
    display: inline-block;
    line-height: 40px;
    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    background-color: #c30000;
    margin-top: 1em;
    text-align: center;

    @media (max-width: 500px) {
        font-size: 14px;

    }
`

export const CloseWindow = styled.button`
    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    border: 1px solid white;
    margin-top: 1em;

    @media (max-width: 500px) {
        font-size: 14px;
    }

    :hover{
        color: black;
        background-color: white;
    }
`

export const CallToAction = styled.button`
    width: 200px;
    height: 60px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    background-color: #c30000;

    @media (max-width: 500px) {
        font-size: 14px;
        width: 170px;
        padding-left: 1em;
        padding-right: 1em;

    }
`

export const CallToActionHrefHomepage = styled.a`
    display: inline-block;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    background-color: #c30000;
    text-align: center;
    vertical-align: middle;


    @media (max-width: 500px) {
        font-size: 14px;
        width: 170px;
        padding-left: 1em;
        padding-right: 1em;

    }
`

export const CallToActionContent = styled.span`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    vertical-align: middle;
`

export const NFTCallToAction = styled.button`
    width: 100%;
    height: 30px;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
    color: white;
    background-color: #dc0bb6;
    margin-top: 1em;
`

export const NFTCallToActionHref = styled.a`
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
    color: white;
    background-color: #c30000;
    margin-top: 1em;
`

export const NFTCallToActionHref2 = styled.a`
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
    color: white;
    background-color: #47158e;
    margin-top: 1em;
`


export const HeroAd = styled.div`
    padding-top: 25px;
    width: 90%;
    height: 420px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: rgba(0,0,0,.2);
    border-radius: 25px;
`

export const HeroAdImage = styled.img`
    width: 250px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
`

export const HeroAdMessage = styled.div`
    width: 80%;
    font-family: Roboto;
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    color: white;
`


export const MindmasterWrapper = styled.a`

    display: flex;
    width: 200px;
    
    @media (max-width: 900px) {

        display:none;

    
    }

`

export const MindmasterMobileWrapper = styled.a`

    display: flex;
    width: 150px;
    margin-left: 0px;
    padding-top: 8px;

    @media (min-width: 901px) {
        display:none;
    }

`
export const MindmasterMobileLogo = styled.img`
    width: 60px;
    max-width: 60px;
    height: 60px;
    max-height: 60px;
    
`


export const MindmasterLogo = styled.img`
    width: 80%;
    max-width: 80%;
    height: auto;
    max-height: 100%;
    margin-left: 20px;
`

export const FooterLeft = styled.div`
    width: 30%;
    max-width: 30%;
    //border: 1px solid yellow;

    @media (max-width: 648px) {
        width: 100%;
        max-width: 100%;
    }

`

export const FooterRight = styled.div`
    display: flex;
    width: 70%;
    max-width: 70%;

    @media (max-width: 648px) {
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
    }

    //border: 1px solid yellow;
`

export const FooterColumn = styled.div`
    width: 33%;
    max-width: 33%;
    //border: 1px solid magenta;
    height: 100%;
    

    @media (max-width: 500px) {
        width: 50%;
        max-width: 50%;

    }

`

export const ColumnTitle = styled.div`
    width: 100%;
    max-width: 100%;
    //border: 1px solid aqua;
    font-size: 14px;
    font-family: Roboto;
    font-weight: bold;
    color: #e3e2e2;
    //padding-left: 1em;
    padding-top: 2em;
`

export const ColumnLinks = styled.div`
    width: 100%;
    max-width: 100%;
    font-size: 12px;
    font-family: Roboto;
    color: #c9c6c6;
    padding-left: 0.2em;
    padding-top: 1em;
`

export const FooterLogo = styled.img`
    margin-top: 30px;
    width: 150px;
    max-width: 150px;
    height: auto;
    max-height: 75px;
    margin-left: 20px;
    margin-right: 15px;
    float: left;
    
`



export const FooterWaxDAOText = styled.div`

    height: 80px;
    padding-top: 1.6em;
    z-index: 5000;
    font-size: 16px;


`


export const ModalChain = styled.a`

    width: 200px;
    max-width: 200px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    margin-left: auto;
    display: inline-block;
    margin-right: auto;
    margin-top: 20px;
    //border: 1px solid white;
`


export const WalletRAMInput = styled.input`

    font-size: 14px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 2px solid #c30000;
    width:80%;
    max-width: 80%;
    padding: 2px;
    border-radius: 5px;
    padding-left: 0.5em;

    :focus{
        outline: 1px solid white;
    }


`

export const WalletSearchBarCont = styled.div`

    //font-size: 20px;
    font-weight: normal;
    color: white;
    width:300px;
    max-width: 80%;
    //margin-left: 2em;
    vertical-align: top;
    position: relative;
    z-index: 7000;

`

export const WalletSearchBarCont2 = styled.div`

    font-size: 20px;
    font-weight: normal;
    color: white;
    width:300px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    vertical-align: top;
    position: relative;
    z-index: 7000;

`

export const WalletSearchBar = styled.input`

    color: white;
    background-color: transparent;
    border: 1px solid #47158e;
    width:90%;
    max-width: 90%;
    padding: 2px;
    border-radius: 5px;
    padding-left: 1em;

    :focus{
        outline: 1px solid white;
    }

`

export const CloseWalletSearchBar = styled.button`

    color: ${theme.secondary};
    background-color: transparent;
    //border: 1px solid #c30000;
    width:9%;
    max-width: 9%;
    padding: 2px;
    border-radius: 5px;
    margin-left: 1%;
    

    :hover{
        background-color: ${theme.secondary};
        color: ${theme.textMain};
    }

`

export const WalletSearchBarCollectionList = styled.div`

    color: white;
    background-color: ${theme.mainDarker};
    width:90%;
    max-width: 90%;
    height: 10em;
    max-height: 80vh;
    border: 1px solid transparent;
    position: absolute;
    top: 43px;
    border-radius: 10px;
    left: 0px;
    
    overflow-y: scroll;
    overflow-x: hidden;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme.secondary} black; 

    ::-webkit-scrollbar{
        height: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme.secondary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }

`

export const WalletSearchBarCollectionList2 = styled.div`

    color: white;
    background-color: #1f1b1b;
    width:100%;
    max-width: 100%;
    height: 10em;
    max-height: 80vh;
    overflow-x: scroll;
    border: 1px solid #47158e;
    position: absolute;
    top: 70px;
`


export const WalletSearchBarSingleCollectionButton = styled.button`

    color: #e1dfdf;
    width:100%;
    max-width: 100%;
    padding: 5px;
    padding-left: 8px;
    text-align: left;
    font-size: 16px;

    :hover{
        background-color: #dfdcdc40;
        transition: background-color 0.5s;
    }

`

export const NavChainImg = styled.img`
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    height: auto;
    width: 100%;
    border: 0.5px solid white;
    border-radius: 3px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;

    :hover{
        border: 1px solid #c30000;
    }
    //border: 1px solid yellow;
   
`


export const WalletSingleFarmEarnedCont = styled.div`
    width: 20%;
    `
export const WalletSingleFarmEarnedTitle = styled.div`
width: 100%;
font-size: 12px;
font-family: Roboto;
`
export const WalletSingleFarmEarnedDetail = styled.div`
width: 100%;
font-size: 10px;
font-family: Roboto;
`
export const WalletCallToAction = styled.button`
    width: 80%;
    padding: 0.5em;
    height: auto;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    color: white;
    background-color: #c30000;
`

export const StakersListViewAssetsButton = styled.button`
    width: 120px;
    padding: 0.5em;
    height: auto;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    color: white;
    background-color: #c30000;
`


export const WalletRefreshButton = styled.button`
    width: 100px;
    padding: 0.5em;
    height: auto;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    background-color: #c30000;
    margin-left: 2em;
`


export const WalletStakeTokenButton = styled.button`
    width: 80%;
    padding: 0.5em;
    height: auto;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    color: white;
    background-color: #c30000;
    margin-top: 0.7em;
`

export const CoolBurnIt = styled.button`

    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    //border: 1px solid white;
    background-color: #dc0bb6;
    margin-top: 1em;

    @media (max-width: 500px) {
        font-size: 14px;
    }

    :hover{
        color: #dc0bb6;
        background-color: transparent;
        border: 2px solid #dc0bb6;
    }
`

export const WalletCallToActionHref = styled.a`
    display: inline-block;
    width: 80%;
    padding: 0.5em;
    height: auto;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    color: white;
    background-color: ${theme.secondary};
`

export const WalletViewPoolIcon = styled.a`
    display: inline-block;
    width: auto;
    height: auto;
    font-size: 24px;
    font-family: Roboto;
    font-weight: 900;
    color: #cec8c8;
    background-color: transparent;
    vertical-align: top;
    padding-top: 2px;
    margin-left: 1em;

    :hover{
        color: #c30000;
    }
`

export const WalletSidebarCont = styled.div`

    width: 300px;
    max-width: 80%;
    height: 100vh;
    position: fixed;
    right: 0px;
    top: 80px;
    background-color: #131313;
    color: white;
    z-index: 10000;
    box-shadow: 0px 7px 10px 0px whitesmoke;
    overflow-x: scroll;
    font-family: Roboto;
    font-weight: 900;

`
export const WalletSidebarTitle = styled.div`
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    padding-top: 1em;
    padding-left: 1em;
    color: white;
`

export const WalletSidebarSeparator = styled.div`
    width: 80%;
    max-width: 80%;
    font-size: 18px;
    padding-top: 1em;

    border-bottom: 1px solid #e2dfdf;
    margin-left: auto;
    margin-right: auto;

    color: white;
`

export const WalletSidebarTitleTwo = styled.div`
    width: 100%;
    max-width: 100%;
    font-size: 18px;

    padding-left: 1em;
    margin-top: 15px;

    color: white;
`

export const WalletSidebarCategory = styled.button`
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    text-align: left;
    padding-top: 1em;
    padding-left: 2em;
    //color: white;
    margin-top: 5px;
`


export const PackCreatorName = styled.div`
    width: 30%;
    font-size: 14px;
    padding-top: 0.5em;
    font-family: Roboto;
    font-weight: 900;

    @media (max-width: 568px) {
        width: 100%;
        padding-bottom: 1em;
    }
    `
export const PackCreatorChooseCollectionCont = styled.div`
    width: 70%;

    @media (max-width: 568px) {
        width: 100%;
    }
`

export const CurrentWalletSectionTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: left;

`

export const WalletNFT = styled.div`
    //border: 1px solid #47158e;
    border-radius: 7px;
    width: 140px;
    max-width: 140px;
    padding:5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 12px;
    word-wrap: break-word;
    background-color: #47158e60;
    box-shadow: 0px 0px 3px 0.1px #858383;
    //color: #d0cece;
    `
export const WalletNFTTopHalf = styled.div`
    width: 100%;
    background-color: rgba(0,0,0,.3);
    //padding: 5px;
    height: 120px;
    border-radius: 3px;
    position: relative;
    justify-content: center;

`

export const WalletNFTTopHalfNameAndMint = styled.div`
    width: 100%;
    //background-color: #47158e60;
    padding: 5px;
    height: 80px;
`




export const WalletNFTLogo = styled.img`
    width: auto;
    max-width: 100px;
    height: 100px;
    max-height: 100px;
    border-radius: 5px; 
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    
`




export const WalletSingleFarm = styled.div`
    display: flex;
    border: 1px solid dimgray;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    padding: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    flex-wrap: wrap;
    justify-content: left;
    font-size: 12px;
    word-wrap: break-word;
    `


export const WalletSingleFarmExpanded = styled.div`
//display: flex;
border-top: 1px solid dimgray;
width: 100%;
max-width: 100%;
padding: 1em;
margin-left: auto;
margin-right: auto;
margin-top: 2em;
flex-wrap: wrap;
justify-content: left;
font-size: 12px;
word-wrap: break-word;
`

export const WalletSingleCollectionExpanded = styled.div`
//display: flex;
border-top: 1px solid dimgray;
width: 100%;
max-width: 100%;
padding: 1em;
margin-left: auto;
margin-right: auto;
margin-top: 2em;
flex-wrap: wrap;
justify-content: left;
font-size: 12px;
word-wrap: break-word;
`
export const WalletSingleCollectionCont = styled.div`
    width: 20%;
    display: inline-block;

    @media (max-width: 700px) {
        width: 100%;
        text-align: center;
        padding-bottom: 1em;
    }
    `

export const AuthorizedTitleCont = styled.div`
    width: 25%;
    display: inline-block;
    text-align: left;
    padding-left: 2em;

    @media (max-width: 700px) {
        width: 100%;
        padding-bottom: 1em;
        padding-left: 0px;
        text-align: center;
    
    }
    `

export const WalletDropDetailsCont = styled.div`
    width: 20%;
    display: inline-block;
    text-align: center;
    padding: 1em;

    @media (max-width: 700px) {
        width: 50%;
        padding-bottom: 1em;
        padding-left: 0px;
    
    }
    `



export const AuthorizedAccountsCont = styled.div`
    width: 55%;
    padding: 2em;
    display: inline-block;

    @media (max-width: 700px) {
        width: 100%;
        text-align: center;
    }
    `



export const WalletClaimPoolName = styled.div`
    width: 20%;
    font-size: 14px;
    padding-top: 0.5em;
    font-family: Roboto;
    font-weight: 900;

    @media (max-width: 700px) {
        width: 100%;
        padding-bottom: 1em;
    }
    `



export const WalletMyCollectionsMainCont = styled.div`
width: 33%;

@media (max-width: 700px) {
    width: 50%;
    padding-bottom: 1em;
}
`



export const WalletUnstakeTokenContLeft = styled.div`
width: 20%;

@media (max-width: 700px) {
    width: 50%;
    padding-bottom: 1em;
}
`

export const WalletUnstakeTokenContLeftWider = styled.div`
width: 30%;

@media (max-width: 700px) {
    width: 50%;
    padding-bottom: 1em;
}
`

export const WalletMyPremintedPacksThird = styled.div`
width: 33%;
text-align: center;

@media (max-width: 700px) {
    width: 50%;
    padding-bottom: 1em;
}
`


export const WalletTokenBalanceCont = styled.div`
    width: 25%;

    @media (max-width: 700px) {
        width: 33%;
    }
    `

export const WalletSingleCreatePool = styled.div`
    display: flex;
    border: 1px solid dimgray;
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    padding: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    flex-wrap: wrap;

    font-size: 12px;
    word-wrap: break-word;
    `

export const HeroLeftSide = styled.div`
    display: inline-block;
    width: 65%;
    max-width: 65%;
    height: 100%;
    padding-right: 15px;
    background-color: #47158e;

    @media (max-width: 847px) {
        width: 100%;
        max-width: 100%;
        display: block;
        text-align: center;
    }

`

export const CallToActionCont = styled.div`
    width: 90%;
    display: flex;
    justify-content: left;
    padding-left: 15px;
    gap: 40px;
    height: 70px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 847px) {
        justify-content: space-around;
    }

`

export const StatsCont = styled.div`
    width: 90%;
    display: flex;
    justify-content: left;
    padding-left: 15px;
    gap: 10px;
    height: 70px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 847px) {
        justify-content: space-around;
    }
`

export const SingleStat = styled.div`
    width: 150px;
    height: 80px;
    font-family: Roboto;
    font-weight: 500;
    color: white;
`

export const SingleStatHeader = styled.div`

    font-size: 30px;
    font-family: Roboto;
    font-weight: 900;
    color: white;

    @media (max-width: 500px) {
        font-size: 24px;
    }
`


export const SocialProofCont = styled.div`
    
    width: 80%;
    max-width: 80%;
    //height: 300px;
    margin-left: auto; 
    margin-right: auto;
    text-align: center;
    //justify-content: space-around;


    color: white;

`

export const SocialProofTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    text-align: center;
    font-size: 24px;
    padding-top: 2em;

    color: white;

`

export const SocialProofLogoCont = styled.div`
    display: inline-block;
    justify-content: space-around;
    width: 80%;
    max-width: 80%;
    margin-left: auto; 
    margin-right: auto;
    padding-top: 20px;
    text-align: center;
    font-size: 24px;
    color: white;

    @media (min-width: 875px) {
        width: 50%;
    }

`



export const HomepageFAQCont = styled.div`

    
    width: 45%;
    max-width: 45%;
    height: auto;
    margin-left: auto; 
    margin-right: auto;
    text-align: left;
    color: white;
    margin-top: 40px;

    @media (max-width: 500px) {
        width: 65%;
        max-width: 65%;
    }

`

export const FAQHref = styled.a`
    color: ${theme2024.primary};
`

export const FooterHref = styled.a`
    :hover{
        color: #c30000;
    }
`

export const NotFoundCont = styled.div`
    height: 300px;
    padding-top: 120px;
    text-align: center;
    color: white;
    font-size: 30px;
    font-family: Roboto;
    font-weight: 900;


`



export const HomepageFAQTitle = styled.div`
    width: 100%;
    max-width: 100%;
    font-size: 24px;
    color: white;

`

export const HomepageFAQDescription = styled.div`
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    color: #cecccc;
    padding-top: 0.8em;
    margin-bottom: 1em;

`
export const HomepageQuestion = styled.div`
    display: inline-block;
    width: 90%;
    max-width: 90%;
    font-size: 16px;
    color: white;
    padding-top: 0.8em;

`

export const HomepageQuestionPlusSign = styled.button`
    display: inline-block;
    width: 10%;
    max-width: 10%;
    font-size: 16px;
    color: white;
    padding-top: 0.8em;
    text-align: center;

`
export const HomepageFAQAnswer = styled.div`
   // display: inline-block;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    color: #d5d4d4;
    padding-top: 0.8em;

`


export const LastCallToActionCont = styled.div`

    width: 90%;
    max-width: 90%;
    padding-top: 30px;
    height: auto;
    margin-left: auto; 
    margin-right: auto;
    justify-content: space-around;
    
    text-align: center;
    color: white;
`


export const LastCallToActionTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    text-align: center;
    font-size: 24px;
    padding-top: 2em;

    color: white;

`



export const HeroRightSide = styled.div`
    display: inline-block;
    width: 35%;
    max-width: 35%;
    height: 100%;
    //border-left: 1px solid white;
    background-color: #47158e;

    @media (max-width: 847px) {
        width: 100%;
        max-width: 100%;
        display: block;
        padding-top: 1.2em;
    }

`

export const SingleStatDetail = styled.div`

    font-size: 16px;
    font-family: Roboto;
    color: #e7e6e6;

    @media (max-width: 500px) {
        font-size: 14px;
    }
`

export const HeroMediumText = styled.div`
    line-height: 1.2em;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 50px;
    font-family: Roboto;
    color: white;

    @media (max-width: 847px) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 1em;
        padding-right: 1em;
    }

    @media (max-width: 500px) {
        font-size: 18px;
    }
`

export const HeroLargeText = styled.div`
    font-size: 54px;
    margin-top: 45px;
    margin-left: 50px;
    font-weight: 500;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    line-height: 1.2em;

    @media (max-width: 847px) {
        margin-left: auto;
        margin-right: auto;
        font-size: 48px;
        padding-left: 1em;
        padding-right: 1em;
    }

    @media (max-width: 500px) {
        font-size: 36px;
    }

`

export const WalletSingleDrop = styled.div`
    //display: flex;
    border: 1px solid dimgray;
    border-radius: 5px;
    width: 90%;
    max-width: 90%;
    padding: 1em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    flex-wrap: wrap;
    justify-content: left;
    font-size: 12px;
    word-wrap: break-word;
    `


export const Wallet20To100 = styled.div`
    width: 20%;
    font-size: 14px;
    padding-top: 0.5em;
    font-family: Roboto;

    @media (max-width: 700px) {
        width: 100%;
        padding-bottom: 1em;
    }
    `


export const WalletSingleDropName = styled.div`
    display: inline-block;
    width: 20%;
    font-size: 14px;
    padding-top: 0.5em;
    font-family: Roboto;
    font-weight: 900;
    `
export const WalletSingleDropCont = styled.div`
width: 20%;
display: inline-block;
`
export const WalletCreatePoolCont = styled.div`
width: 50%;
display: inline-block;
text-align: center;
padding: 0px;
`

export const WalletCreatePoolSearchCont = styled.div`
width: 100%;
text-align: center;
padding: 0px;
`



export const WalletMyCollectionsName = styled.div`

width: 33%;
font-size: 14px;
padding-top: 0.5em;
font-family: Roboto;
    font-weight: 900;

@media (max-width: 700px) {
    width: 100%;
    padding-bottom: 1em;
}
`


export const WalletEditFarmButtonCont = styled.div`
    width: 20%;

    @media (max-width: 700px) {
        width: 100%;
    }
    `

export const WalletPremintedPoolsCont = styled.div`
    width: 20%;

    @media (max-width: 700px) {
        width: 33%;
        padding-bottom: 1em;
    }
    `


export const WalletTokenBalanceName = styled.div`
    width: 25%;

    @media (max-width: 700px) {
        width: 100%;
        padding-bottom: 1em;
    }

    `

export const WalletUnstakeTokenName = styled.div`
    width: 20%;
    font-size: 14px;
    padding-top: 0.5em;
    font-family: Roboto;
    font-weight: 900;

    @media (max-width: 700px) {
        width: 100%;
        padding-bottom: 1em;
        
    }

    `
export const WalletMyPremintedPacksActions = styled.div`
width: 33%;
text-align: center;
padding-bottom: 1em;

`

export const WalletUnstakeTokenContRight = styled.div`
width: 20%;

@media (max-width: 700px) {
    width: 100%;
    padding-bottom: 1em;
}
`


export const CollectionRamCont = styled.div`
    width: 20%;
    text-align: left;
    padding-left: 2em;
    display: inline-block;

    @media (max-width: 700px) {
        width: 100%;
        padding-bottom: 1em;
        text-align: center;
        padding-left: 0px;
    }
    `


export const WalletNFTVideo = styled.video`
    width: auto;
    max-width: 100px;
    height: 100px;
    max-height: 100px;
    border-radius: 5px; 
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
`

export const WalletMessage = styled.div`
    width: 90%;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    border: 1px solid dimgray;
    opacity: 90%;
    background-color: #c30000;
    border-radius: 10px;
    padding: 15px;

`

export const BlendMessage = styled.div`
    width: 90%;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    border: 1px solid dimgray;
    
    background-color: #7b04cabc;
    border-radius: 10px;
    padding: 15px;

`

export const AdsCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    gap: 1em;

`

export const BannerPreview = styled.img`
    
    width: 1280px;
    max-width: 100%;
    height: auto;
    max-height: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;



`

export const AdSlot = styled.button`
    position: relative;
    border-radius: 7px;
    width: 160px;
    max-width: 160px;
    height: 220px;
    padding:5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 14px;
    word-wrap: break-word;
    background-color: ${theme2024.secondaryDark};
    color: white;
    font-family: Roboto;
    font-weight: 900;
    border: 1px solid ${props => props.selected == true ? theme2024.primary : "transparent"};

    :hover{
        background-color: #dfdcdc40;
        transition: background-color 0.5s;
    }

`

export const BlendMessageLink = styled.a`
    color: #ff0000;
    font-family: Roboto;
    font-weight: 900;

`

export const WalletBlendRAMCont = styled.div`
    width: 25%;

    @media (max-width: 700px) {
        width: 50%;
        padding-bottom: 1em;
    }
    `

export const Wallet20To50 = styled.div`
    width: 20%;

    @media (max-width: 700px) {
        width: 50%;
        padding-bottom: 1em;
    }
    `

export const WalletSidebarSubcategoryCont = styled.div`
    width: 100%;
    max-width: 100%;
    text-align: left;
    padding-left: 2em;
    color: white;
`

export const WalletSidebarSubcategory = styled.button`
    width: 100%;
    max-width: 100%;
    font-size: 12px;
    text-align: left;
    padding-top: 0.5em;
    padding-left: 1em;
    //color: white;
    margin-top: 5px;
`
export const TokenBalanceCallToAction = styled.button`
    width: 80%;
    padding: 0.5em;
    height: auto;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    color: white;
    background-color: #c30000;
    margin-top: 1em;
`

export const WalletStakeableToken = styled.button`
    width: 80%;
    margin-top: 1em;
    padding: 0.5em;
    height: auto;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    color: white;
    background-color: #c30000;
`

export const ModalChainImg = styled.img`
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    height: auto;
    width: 80%;
    border: 0.5px solid white;
    border-radius: 10px;
    padding: 3px;
    padding-left: 30px;
    padding-right: 30px;
    //border: 1px solid yellow;

    :hover{
        border: 1px solid #c30000;
    }
   
`


export const WalletIcon = styled.img`
    width: 30px;
    max-width: 30px;
    height: 30px;
    max-height: 30px;
    position: fixed;
    right: 25px;
    top: 25px;
`

export const WalletIconButton = styled.button`
    width: 30px;
    max-width: 30px;
    height: 30px;
    max-height: 30px;
    position: fixed;
    right: 25px;
    top: 25px;
`

export const WalletIconButtonWrapper = styled.div`
    display: flex; //need to hide on mobile
    flex-direction: column;
    justify-content: center;
    width: 120px;
    max-width: 120px;
    height: 80px;
    max-height: 80px;
    position: fixed;
    right: 25px;
    top: 0px;

    @media (max-width: 900px) {
        display: none;
    }

`

export const WaxDAOText = styled.button`
    width: 90px;
    max-width: 90px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    z-index: 5000;
    font-size: 16px;


    :hover{
        cursor: pointer;
    }

`


export const WojakCallToAction = styled.a`
    display: inline-block;
    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 900;
    color: white;
    background-color: #c30000;
`

export const FeaturesCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;

`

export const SingleFeature = styled.a`
    display: inline-block;
    width: 30%;
    max-width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    padding: 1em;
    padding-top: 3em;

    @media (max-width: 648px) {
        width: 90%;
        max-width: 90%;
    }

    
`

export const BenefitsCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    align-items: center;
`

export const SingleBenefitPic = styled.img`
    display: inline-block;
    width: 200px;
    max-width: 200px;
    height: 200px;
    max-height: 200px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    opacity: 90%;

    @media (max-width: 748px) {
       
       width: 150px;
       max-width: 150px;
       height: 150px;
       max-height: 150px;
  }

    @media (max-width: 565px) {
       
        display: none;
   }


`

export const SingleBenefitTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 22px;
    font-family: Roboto;
    font-weight: 500;
    text-align: center;

    @media (max-width: 748px) {
        font-size: 18px;
    }
`

export const SingleBenefitDescription = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    color: #dedddd;
    font-size: 14px;
    font-family: Roboto;
    text-align: left;
`

export const SingleBenefitText = styled.div`
    display: inline-block;
    width: auto;
    max-width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    padding: 1em;
    padding-top: auto;

`


export const TrustedBySmall = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 14px;
    font-family: Roboto;
    text-align: left;
`


export const MobileModalOverlay = styled.div`
   
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
   
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background-color: #1c1c1c;
    opacity: 80%;

    @media (min-width: 749px) {
        display: none;
    }
    

`

export const ModalContent = styled.div`

    color: white;
    font-size: 14px;
    a{
        color: ${theme.secondary}
    }
    

`

export const Spinner = styled.div`

    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    //mix-blend-mode: overlay;
    

`

export const SpinnerRed = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: whitesmoke;
    animation: rotate 0.6s linear infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}


`

export const SpinnerBlue = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid transparent;
    border-left-color: whitesmoke;
    animation: rotate 0.6s linear infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}

`

export const SpinnerGreen = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid transparent;
    border-right-color: whitesmoke;
    animation: rotate 0.6s linear infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}

`




export const LoadSpinner = styled.div`

    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    //mix-blend-mode: overlay;
    

`

export const LoadSpinnerRed = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: whitesmoke;
    animation: rotate 1.5s ease-in-out infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}


`

export const LoadSpinnerBlue = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-right-color: #696969;
    animation: rotate 2s ease-out infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}


`

export const LoadSpinnerGreen = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-left-color: ${theme.secondary};
    animation: rotate 2.5s ease-in infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}

`





export const HomePageCont = styled.div`
    //display: flex;
    width: 100%;
    max-width: 100%;
    //height: 460px;
    margin-left: 0px; 
    margin-right: 0px;
    margin-top: 70px;
    background-color: #47158e;
    padding-bottom: 2em;

    @media (max-width: 847px) {
        padding-top: 0.5em;
    }

`

export const HomePageCont2 = styled.div`
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid dimgrey; 
    margin-left: 0px; 
    margin-right: 0px;
    padding-bottom:20px;
    padding:2em;
    margin-top: 0px;

`

export const HomeTitle = styled.div`
    
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 34px;
    font-weight: bold;
    text-align: center;
`

export const HomeTitle2 = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
`

export const HomeDescription = styled.div`
    width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    font-weight: normal;
    text-align: center;

`

export const TrustedByCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    padding: 1em;
    justify-content: center;

`

export const TrustedByTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 1em;
`

export const TrustedByDiv = styled.div`
    display: inline-block;
    width: 20%;
    max-width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 1em;

    @media (max-width: 768px) {
        width: 80%;
        max-width: 80%;
        margin-top: 25px;

    }
    
`

export const ModalLink = styled.a`
    color: #fa872d;



    :hover{
        cursor: pointer;
    }
    
`


export const TrustedByLink = styled.a`
    display: inline-block;
    width: 20%;
    max-width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    border: 1px solid #696969;
    border-radius: 10px;
    padding: 1em;

    @media (max-width: 768px) {
        width: 80%;
        max-width: 80%;
        margin-top: 25px;

    }

    :hover{
        border: 1px solid #fa872d;
    }
    
`

export const MainContainer = styled.div`
    width:100%;
    padding:0;
    box-sizing: border-box;
    margin-bottom: 50px;
    font-family: Roboto;

`


export const LeftDiv = styled.div`
    width: 25%;
    max-width: 25%;
    border: 1px solid #fa872d; 
    position: fixed; 
    background-color: #1c1c1c;
    //height: 90vh; 
    
    margin-left: 20px; 
    border-radius: 5px;
    height:80%;
    //max-height: auto;
    overflow-x: scroll;

    @media (max-width: 900px) {
       
        display: none;
    }

`


export const ExpandLargeNav = styled.div`
    width: 100%;
    max-width: 100%;
    top: 70px; 
    opacity: 90%;
    color: white;
    text-align: center;
    font-size: 12px;
    background-color: black;
    position: fixed;
    z-index: 5000;

`



export const RightNav = styled.div`
    width: 100%;
    max-width: 100%;
    position: absolute; 
    background-color: #202329;
    height: 70px; 
    opacity: 90%;
    display: flex;
    color: white;
    text-align: center;
    font-size: 12px;
    justify-content: center;
    top: 0px;
    

    @media (min-width: 901px) {

        
        width: 45%;
        
        position: absolute;
        right: 0px;


        
    }

`



export const NewNavLink = styled.button`
    width: 90px;
    max-width: 90px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    display: none;
    z-index: 5000;
    font-size: 14px;


    :hover{
        background-color: #1c1c1c;
        color: whitesmoke;
        opacity: 100%;
        cursor: pointer;
    }


    @media (min-width: 749px) {

        display: flex;

    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    
    }

`




export const ExpandedNavLink = styled.a`
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;


    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    


`



export const ExpandedNavHeader = styled.button`
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;


    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    
`


export const ExpandedNavButton = styled.button`
    width: 100%;
    max-width: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;


    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    
`



export const ExpandedNavBody = styled.a`
    width: 100%;
    max-width: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;


    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    
`





export const NavWallet = styled.button`
    width: 150px;
    max-width: 150px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    margin-left: auto;
    display: none;

    @media (min-width: 991px) {

    display: flex;

    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    
    }


`




export const NavChain = styled.button`

    width: 85px;
    max-width: 85px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    display: inline-block;
    margin-right: 100px;
    margin-left: auto;

    @media (max-width: 400px) {
   
        margin-right: 70px;

    }
`


export const MobileNavCont = styled.button`
    position: absolute;
    right: 10px;
    top: 20px;
    display: block;
    width: 50px;
    padding-bottom: 6px;

    @media (min-width: 901px) {
        display:none;
    }

    :hover{
        cursor: pointer;
    }

`

export const NewUIFarmsPageCont = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:1em;
    margin-top: 100px;

`


export const NewUISingleFarmCont = styled.div`
    display: flex;
    border: 1px solid dimgrey;
    border-radius: 15px;
    //width: 80%;
    //max-width: 250px;
    padding:40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    height: 400px;

    :hover{
        border-color: #fa872d;
        cursor: pointer;

        img{
            box-shadow: 0px 0px 5px 1px whitesmoke;
        }

    }

    @media (max-width: 500px) {
        width: 100%;
        max-width: 100%;
        padding: 10px;
        height: auto;
        border-radius: 5px;
        
    }
    
`

export const NewUIDropType = styled.div`
    width: 100px;
    max-width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 12px;
        font-weight: 500;

        
    }
`

export const NewUISingleDropLogo = styled.img`

    width: auto;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 5%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 900px) {
        display: inline-block;
        width: auto;
        max-width: 100px;
        padding: 10px;
        height: 75px;
        max-height: 75px;
        margin-left: 0px;
        
    }



`





export const RightDiv = styled.div`
    width: 65%;
    max-width: 65%;
    border: 1px solid #fa872d; 
    margin-left: 30%; 
    border-radius: 5px;
    padding-bottom: 50px;
    margin-top: 100px;

    @media (max-width: 900px) {
        width: 90%;
        max-width: 90%;
        border: 1px solid #fa872d; 
        margin-left: auto;
        margin-right: auto;
    }

`

export const ArticleCont = styled.div`
    width: 90%;
    max-width: 90%;
    color: whitesmoke;
    border: 1px solid #fa872d; 
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 50px;
    margin-top: 100px;

    ul{
        margin-left: 2em;
        line-height: 2em;
    }

    a{
        color: #fa872d;
    }


`



export const MyDropsCont = styled.div`
    
    width: 100%;
    max-width: 100%;
    color: whitesmoke;
    border: 1px solid #fa872d; 
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 50px;
    margin-top: 10px;
    display: flex;
    overflow-x: scroll;
    margin-bottom: 50px;



`



export const ArticleH1 = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 30px;
    font-weight: 500;
    

`


export const ArticleH2 = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    text-align: left;
    font-size: 24px;
    font-weight: 500;
    color: #fa872d;


`

export const ArticleP = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    text-align: left;
    font-size: 16px;
    font-weight: 200;


`






export const StakeManyDiv = styled.button`
z-index: 8000;
    width: 150px;
    max-width: 150px;
    background-color: ${theme.secondary};  
    color: white;
    border-radius: 15px;
    padding: 10px;
    bottom: 75px;
    left: 25px;
    position: fixed;
    text-align: center;
    animation: blinkingBackground 4s infinite;


	@keyframes blinkingBackground{
		0%		{ background-color: #10c018;}
		//25%		{ background-color: #1056c0;}
		//50%		{ background-color: #ef0a1a;}
		//75%		{ background-color: #254878;}
		100%	        { background-color: #04a1d5;}
	}


    :hover{
        background-color: ${theme.secondaryHover};
        transition: background-color 1s;
    }
   
`

export const RemoveManyDiv = styled.button`
    width: 150px;
    max-width: 150px;
    background-color: ${theme.secondary};  
    color: white;
    border-radius: 15px;
    padding: 10px;
    bottom: 75px;
    left: 25px;
    position: fixed;
    text-align: center;
    box-shadow: 0px 0px 5px 0.8px whitesmoke;
    z-index: 1000;
`


export const NavPageCont = styled.div`
    width: 90%;
    max-width: 90%;
    border: 1px solid #fa872d; 
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    margin-top: 100px;

`

export const StakersCont = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    padding-bottom: 3em;

`

export const StakersHeader = styled.div`
    margin-left: auto;
    margin-right:auto;
    display: block;
    margin-bottom: 10px;
    width: 90%;
    max-width: 90%;
    border-bottom: 1px solid #696969;
    color: whitesmoke;

`

export const StakersRow = styled.div`
    margin-left: auto;
    margin-right:auto;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 14px;
    width: 90%;
    max-width: 90%;
    border-bottom: 1px solid #696969;

    @media (max-width: 500px) {
        font-size: 12px;
    
    }

`

export const StakeLeftRow = styled.div`
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    width: 50%;
    max-width: 50%;
    text-align: left;
    font-weight: 200;

`

export const StakeRightRow = styled.div`
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    width: 50%;
    max-width: 50%;
    text-align: right;
    font-weight: 200;


`

export const StakersLeft = styled.div`
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    width: 60%;
    max-width: 60%;
    text-align: left;
    font-weight: 500;

`

export const StakersRight = styled.div`
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    width: 40%;
    max-width: 40%;
    text-align: right;
    font-weight: 500;


`


export const FarmsPageCont = styled.div`
    width: 90%;
    max-width: 90%;
    border: 1px solid dimgrey; 
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    margin-top: 100px;

`


export const HighlightSlot = styled.button`
    width: 90%;
    max-width: 90%;
    //border: 1px solid dimgrey; 
    margin-left: auto; 
    margin-right: auto;
    border-radius: 10px;
    padding:1.5em;
    margin-top: 10px;

    :hover{
        box-shadow: 0px 0px 5px 4px whitesmoke;
        cursor: pointer;
    }

`


export const HighlightedFarm = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    color: lightgray;
    font-size: 14px;
    //text-align: center;
    //border: 1px solid #fa872d;
    border-radius: 15px;
    padding: 20px;

    @media (max-width: 560px) {
       
       display: block;
       text-align: center;
       
   }

   :hover{
        box-shadow: 0px 0px 5px 4px whitesmoke;

        img{
            box-shadow: 0px 0px 5px 4px whitesmoke;
        }
   }

`
export const HighlightedLogo = styled.img`
    display: inline-block;
    width: 150px;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 50%; 

    
`

export const HighlightDetails = styled.div`
    margin-left: auto;
    margin-right:auto;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    padding: 15px;

    @media (max-width: 560px) {
       text-align: center;
   }
`

export const HighlightTitle = styled.div`
    width: 100%;
    max-width: 100%;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: left;

    @media (max-width: 560px) {
       text-align: center;
   }
`
export const HighlightMessage = styled.div`
    width: 100%;
    max-width: 100%;
    color: white;
    font-size: 16px;
    font-weight: 200;
    text-align: left;
    margin-top: 10px;

    @media (max-width: 560px) {
       text-align: center;
   }
`

export const HighlightAmount = styled.div`
    width: 100%;
    max-width: 100%;
    color: white;
    font-size: 16px;
    font-weight: 200;
    text-align: left;
    margin-top: 10px;

    @media (max-width: 560px) {
       text-align: center;
   }


`


export const AllFarmsCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    max-width: 100%;
    margin-top: 15px;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    justify-content: center;

`


export const FilterMenu = styled.select`
    
    font-size: 16px;
    font-weight: normal;
    color: ${testTheme.textSecondary};
    background-color: ${testTheme.mainDark};
    border: 1px solid #bcbcbc;
    width: 150px;
    max-width: 150px;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-left: auto;
    margin-right: auto;
    
`

export const SQLLabel = styled.label`
    font-size: 14px;
`

export const SQLInput = styled.input`
    color: black;
    font-size: 14px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    max-width: 90%;
    margin-bottom: 20px;
    
`




export const AdminPanelTopMenu = styled.div`
    width: 90%;
    text-align: center;
    margin-top: 20px;
    color: #bcbcbc;
    border: 1px solid #bcbcbc;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
`

export const NewBlogCont = styled.div`
    width: 100%;
    text-align: center;
    color: white;
    margin-top: 30px;
`

export const SQLTextarea = styled.textarea`
    color: black;
    font-size: 14px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    max-width: 90%;
    margin-bottom: 20px;
`

export const DropFilterButton = styled.button`

    font-size: 12px;
    font-weight: 500;
    //color: #bcbcbc;
    background-color: transparent;
    //border: 1px solid #bcbcbc;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px;
    height: 40px;

    :hover{
        background-color: #161616;
        //color: white;
        //border: 1px solid white;
    }



`

export const SuggestionsCont = styled.div`
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    justify-content: center;
    text-align: center;

    a{
        color: #fa872d;
    }

`


export const MyDropsContainer = styled.div`
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    justify-content: center;


`

export const SingleFarmCont = styled.div`
    border: 1px solid dimgrey;
    border-radius: 15px;
    width: auto;
    max-width: 250px;
    padding:40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    height: 400px;

    :hover{
        border-color: #fa872d;
        cursor: pointer;

        img{
            box-shadow: 0px 0px 5px 1px whitesmoke;
        }

    }

    @media (max-width: 900px) {
        width: auto;
        max-width: 150px;
        padding: 10px;
        height: 300px;
        
    }
    
`


export const NewestSingleDropCont = styled.div`
    border: 1px solid dimgrey;
    border-radius: 15px;
    width: auto;
    max-width: 250px;
    padding:20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    height: 400px;

    :hover{
        border-color: #fa872d;
        cursor: pointer;

        img{
            box-shadow: 0px 0px 5px 1px whitesmoke;
        }

    }

    @media (max-width: 900px) {
        width: auto;
        max-width: 150px;
        padding: 8px;
        height: 320px;
        
    }
    
`








export const SingleDropCont = styled.div`
    display: inline-block;
    border: 1px solid dimgrey;
    border-radius: 15px;
    width: 300px;
    max-width: 100%;
    padding:40px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 25px;
    justify-content: center;
    text-align: center;

    :hover{
        border-color: #fa872d;
        cursor: pointer;

        img{
            box-shadow: 0px 0px 5px 1px whitesmoke;
        }

    }
    

`

export const RamModal = styled.div`
    border: 1px solid dimgrey;
    border-radius: 15px;
    max-width: 100%;
    padding:40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;

    :hover{
        border-color: #fa872d;
        cursor: pointer;

        img{
            box-shadow: 0px 0px 5px 1px whitesmoke;
        }

    }
    

`

export const FarmLogo = styled.img`

    width: auto;
    max-width: 120px;
    height: 120px;
    max-height: 120px;
    border-radius: 5%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 900px) {
        width: auto;
        max-width: 100px;
        padding: 10px;
        height: 100px;
        max-height: 100px;
        
    }

`

export const SingleDropLogo = styled.img`

    width: auto;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 5%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 900px) {
        width: auto;
        max-width: 100px;
        padding: 10px;
        height: 100px;
        max-height: 100px;
        
    }



`



export const DropLogo = styled.img`

    width: 120px;
    max-width: 120px;
    height: 120px;
    max-height: 120px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;

`

export const FarmTitle = styled.div`
    width: 125px;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 14px;
        font-weight: 500;

        
    }
`


export const DropType = styled.div`
    width: 100px;
    max-width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 12px;
        font-weight: 500;

        
    }
`


export const PoolAmount = styled.div`
    width: 125px;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 14px;
        font-weight: 500;

        
    }
`

export const PoolAPY = styled.div`
    width: 125px;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: #25a725;
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 12px;
        font-weight: 500;

        
    }
`

export const FarmButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: whitesmoke;
    background-color: transparent;
    border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;
    opacity: 90%;

    :hover{
        color: #fa872d;
        border-color: #fa872d;
        opacity: 100%;
    }
`

export const FarmEndDate = styled.div`
    width: 125px;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
`

export const DropIsLive = styled.div`
    width: 125px;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: #50c050;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    font-family: Roboto;
    font-style: italic;
`

export const SoldOut = styled.div`
    width: 125px;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: indianred;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
`


export const TemplatesCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    padding: 5px;
    justify-content: center;

`


export const SingleTemplate = styled.div`

    font-size: 18px;
    font-weight: 200;
    color: whitesmoke;
    background-color: transparent;
    border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: #fa872d;
        border-color: #fa872d;
        opacity: 100%;
    }
`


export const UnboxTemplate = styled.div`
    display: inline-block;
    font-size: 14px;
    font-weight: 200;
    color: whitesmoke;
    width: 100px;
    max-width: 100px;
    background-color: transparent;
    border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;
    margin-top:10px;
    margin-right: 1em;
    text-align: center;

    :hover{
        color: #fa872d;
        border-color: #fa872d;
        opacity: 100%;
    }
`


export const MyTokensItem = styled.div`

    font-size: 14px;
    font-weight: 200;
    color: whitesmoke;
    width: 80%;
    background-color: transparent;
    border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;
    margin-top:10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

`

export const MyTokens = styled.div`
    display: flex;
    text-align: center;
    flex-wrap: wrap;



`

export const MyTokensWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;


`


export const NavPageInnerCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    justify-content: center;


    @media (max-width: 768px) {
        margin-top: 100px;
    }
`


export const NavPageItem = styled.a`
    display: flex;
    width: 150px;
    max-width: 150px;
    min-width: 150px;
    height: 150px;
    max-height: 150px;
    margin-top: 15px;
    color: #A9A9A9;
    border: 1px solid #696969;
    border-radius: 10px;
    font-size: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;

    :hover{
        cursor: pointer;
        color: whitesmoke;
        border-color: whitesmoke;
    }

`
export const DropPageAdBannerCont = styled.div`
    width: 95%;
    max-width: 95%;
    margin-left: auto; 
    margin-right: auto;
    //margin-top: 80px;

`

export const DropPageCont = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:1em;
    margin-top: 10px;
    color: whitesmoke;
`



export const CreateDaoCont = styled.div`
    width: 90%;
    max-width: 90%;
    border: 1px solid #fa872d; 
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    margin-top: 100px;

`

export const DeployCont = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    margin-top: 10px;
    color: #e2dfdf;
    text-align: center;

`



export const CustomizeDaoCont = styled.div`
    width: 90%;
    max-width: 90%;
    border: 1px solid #fa872d; 
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    color: white;
    text-align: center;
    margin-top: 100px;

`


export const DaoName = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    text-align: center;
    font-weight: bold;

`

export const Creator = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;
    font-style: italic;

`


export const Proposals = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

`

export const NewProposal = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

    h2{
        font-size: 20px;
        font-weight: 900;
    }

    h3{
        font-size: 16px;
        font-weight: 900;
    }

`

export const AboutDAO = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

`

export const Wallet = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

`

export const DaoMenu = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    color: white;
    font-size: 18px;


`

export const DaoMenuItem = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    text-align: left;

    :hover{
        cursor: pointer;
    }

`


export const FarmMenu = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    color: white;
    font-size: 18px;


`

export const FarmMenuItem = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    text-align: left;

    :hover{
        cursor: pointer;
        color: #fa872d;
    }

`


export const FarmTopMenu = styled.div`
    display: none; 
    width: 96%;
    max-width: 96%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    color: white;
    font-size: 16px;

    @media (max-width: 900px) {
        display: flex;
        margin-top: 100px;
    }


`

export const FarmTopMenuItem = styled.div`
    display: none; 
    width: 25%;
    max-width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    text-align: center;
    justify-content: center;
    
    :hover{
        cursor: pointer;
        color: #fa872d;
    }

    @media (max-width: 900px) {

        display: inline-flex;
    }

    :hover{
        cursor: pointer;
        color: #fa872d;
    }

`




export const DaoTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
`

export const PoolAssetsTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
`

export const FarmsTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
`



export const DropTitle = styled.div`
    display: inline-block;
    width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
`

export const DropBody = styled.div`
    display: inline-block;
    width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: lightgray;
    font-size: 14px;
    font-weight: 200;
    text-align: left;
`

export const RequiredIngredientsCont = styled.div`
    //display: inline-block;
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: lightgray;
    font-size: 14px;
    font-weight: 200;
    //text-align: left;
    //border: 1px solid yellow;
`


export const StakingCont = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

`

export const NFTsCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    //border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;

`


export const AttributesCont = styled.div`

    justify-content: left;
    gap: 15px;
    //border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;

`


export const NFTLogo = styled.img`
    width: auto;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 5%;
    margin-left: auto;
    margin-right: auto;
`

export const CreateDropIPFS = styled.img`
    width: auto;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 5%;
    margin-left: auto;
    margin-right: auto;
`

export const CreateFarmIPFS = styled.img`
    width: auto;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 5%;

`



export const NavLogo = styled.img`
    width: auto;
    max-width: auto;
    height: 100%;
    max-height: 100%;
`


export const DropImage = styled.img`
    width: 200px;
    max-width: 200px;
    height: auto;
    border-radius: 2%; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
`


export const ProfileImage = styled.img`
    width: auto;
    max-width: 80%;
    height: auto;
    max-height: auto;
    border-radius: 10px;
    margin-left: auto;
    margin-right: 15px;
    margin-bottom: 20px;
`

export const LeftProfileImg = styled.img`
    width: auto;
    max-width: 80%;
    height: auto;
    max-height: auto;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 20px;
`




export const MyDaoTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 10px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
`

export const SingleProposal = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;
    padding-top: 5px;

`

export const Suggestion = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: 200;
    text-align: left;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;

`

export const SuggestionDesc = styled.div`
    width: 100%;
    max-width: 100%;
    color: white;
    font-size: 14px;
    font-weight: 200;
    text-align: left;

`


export const SuggestionReply = styled.div`
    width: 100%;
    max-width: 100%;
    color: indianred;
    font-size: 14px;
    font-weight: 200;
    text-align: left;
    margin-top: 10px;

`




export const SuggestionDeets = styled.div`
    width: 100%;
    max-width: 100%;
    color: #dbd7d7;
    font-size: 12px;
    font-weight: 200;
    text-align: left;
    margin-top: 15px;
`

export const SuggestionUser = styled.div`
    display: inline-block;
    width: 30%;
    max-width: 30%;
    color: #9e9c9c;
    font-size: 12px;
    font-weight: 200;
    text-align: center;
`


export const StakingPoolMsg = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;

`



export const CreateFarmMsg = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;



`



export const ProposalTitle = styled.div`
    color: ${theme2024.primary};
    font-size: 20px;
    font-weight: 500;

    @media (max-width: 760px) {
        font-size: 16px;
    }     

`

export const ProposalAuthor = styled.div`

    font-size: 12px;
    font-weight: normal;
    margin-bottom: 15px;
    color: lightgray;

`

export const ProposalDescription = styled.div`

    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
    color: ${theme2024.textSecondaryDarker};

    @media (max-width: 760px) {
        font-size: 12px;
    }    
`


export const DropDescription = styled.div`

    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
    color: lightgray;
    border: 1px solid #696969;
    margin-top: 30px;
    padding: 20px;
    width: 95%;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;

`



export const ProposalButton = styled.div`
    display: inline-block;
    border: 1px solid #fa872d;
    width: auto;
    max-width: 180px;
    border-radius: 10px;
    text-align: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: normal;
`

export const ProposalButtonContainer = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
    max-width: 100%;
    justify-content: right;

`
export const ProposalEndTime = styled.div`
    width: 50%;
    text-align: left;
    border: 1px solid white;
`

export const ProposerTypeMessage = styled.div`

    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 15px;
    color: lightgray;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;

`

export const NotLoggedIn = styled.div`

    font-size: 16px;
    font-weight: normal;
    color: lightgray;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;
    width: 80vw;
    max-width: 80vw;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;

`


export const MissingInfoMsg = styled.div`

    font-size: 20px;
    width: 90%;
    max-width: 90%;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 15px;
    color: indianred;
    border: 1px solid indianred;
    border-radius: 10px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 769px) {
        font-size: 14px; 
    }


`

export const StakedAmount = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    text-align: center;
    font-size: 18px;

`

export const AboutDaoCont = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    padding-left: 2em;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;

`

export const ProfileCont = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    padding-left: 2em;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;

`

export const TimeSlotsCont = styled.div`
    border: 1px solid #696969;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 25px;

`

export const WalletCont = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:10px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    text-align: center;
    font-size: 18px;

`



export const NewRow = styled.div`
    flex-basis: 100%;

`


export const BurnableCont = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    gap:5px;
`

export const DAO = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    max-width: 100%;
    padding:15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    display: inline-block;
    text-align: center;
    

`

export const DaosCont = styled.div`
    width:100%;
    max-width: 100%;
    padding:5px;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    flex-wrap: wrap;
    justify-content: space-around;
    display: flex;
`

export const NFT = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    max-width: 100%;
    padding:15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    
`

export const RequiredIngredient = styled.div`

    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:0.5em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    
`


export const Attribute = styled.button`
    border: 1px solid #fa872d;
    border-radius: 15px;
    max-width: 100%;
    padding:15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    
`


export const Drop = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width: 100%;
    max-width: 100%;
    padding:0px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    

`


export const BuyWojak = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    max-width: 100%;
    padding:15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    

`

export const CreateDaoInfo = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:15px;
    padding-left: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 50px;
    color: white;

`


export const Terms = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:15px;
    padding-left: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 50px;
    color: white;

`

export const AssetNote = styled.div`

    width:100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    color: white;
    text-align: center;
    justify-content: center;

`

export const DaoLink = styled.a`

    color: #fa872d;
    text-decoration: none;

`

export const AboutDaoTitle = styled.div`

    font-size: 20px;
    font-weight: 500;
    color: white;
    text-align: left;

`

export const NoDaosMessage = styled.div`

    font-size: 20px;
    font-weight: 500;
    color: white;
    text-align: left;

`



export const AboutDaoBody = styled.div`

    font-size: 16px;
    font-weight: normal;
    color: lightgray;
    text-align: left;
    margin-bottom: 20px;

`

export const ProposerList = styled.div`

    font-size: 16px;
    font-weight: normal;
    color: lightgray;

`

export const ProposalInput = styled.input`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width:80%;
    max-width: 80%;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-bottom: 15px;


`


export const QuantityToBuy = styled.input`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 70px;
    max-width: 70px;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-bottom: 15px;
    margin-top: 5px;


`

export const QuantityToDeposit = styled.input`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 120px;
    max-width: 120px;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-bottom: 15px;
    margin-top: 5px;


`


export const PropDescription = styled.textarea`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width:80%;
    max-width: 80%;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;

`

export const EditDropDescription = styled.textarea`

    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width:100%;
    max-width: 100%;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    @media (max-width: 700px) {
        width: 100%;
        text-align: center;
    }

`

export const EditDropTitleCont = styled.div`
    width: 100%;
    display: inline-block;
    text-align: center;
    padding-bottom: 1em;
    font-family: Roboto;
    font-weight: 900;


    `

export const EditDropDescriptionCont = styled.div`
    width: 100%;
    padding: 2em;
    display: inline-block;

    @media (max-width: 700px) {
        width: 100%;
        text-align: center;
    }
    `


export const ProposalChoices = styled.textarea`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width:80%;
    max-width: 80%;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-bottom: 5px;
    margin-left: 10%;
    margin-right: 10%;

`



export const UserWhitelist = styled.textarea`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width:80%;
    max-width: 80%;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-bottom: 5px;
    margin-left: 10%;
    margin-right: 10%;

`

export const RemoveButton = styled.button`

    font-size: 24px;
    font-weight: bold;
    color: ${theme2024.danger};
    background-color: transparent;
    border: 1px solid ${theme2024.danger};
    width: 3em;
    max-width: 3em;
   
    border-radius: 20px;
    display: inline-block;
    margin-bottom: 15px;

    :hover{
        background-color: ${theme2024.danger};
        color: black;
    }
`

export const ToggleSwitchCont = styled.div`

    right: 10%;
    position: absolute;
    display: inline-block;

`

export const ToggleMessage = styled.span`

    font-size: 16px;

`

export const ToggleSwitch = styled.button`

    font-size: 24px;
    font-weight: bold;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 2em;
    max-width: 2em;
    height: 1em;
    max-height: 1em;
    padding: 0px;
    margin-top: 0.5em;
    border-radius: 20px;
    display: inline-block;
    vertical-align: middle;
    line-height: 0em;
    margin-bottom: 15px;


`

export const InnerSwitch = styled.div`

    font-size: 24px;
    font-weight: bold;
    color: white;
    background-color: white;
    width: 50%;
    max-width: 50%;
    height:95%;
    max-height: 95%;
    border-radius: 20px;
    margin: 0px;
    opacity: 90%;

    

`

export const AddButton = styled.button`

    font-size: 24px;
    font-weight: bold;
    color: ${theme2024.primary};
    background-color: transparent;
    border: 1px solid ${theme2024.primary};
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        background-color: ${theme2024.primary};
        color: black;
    }


`

export const AddIngredient = styled.button`

    font-size: 18px;
    font-weight: normal;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        background-color: #fa872d;
        color: black;
    }


`



export const AddNFT = styled.button`

    font-size: 16px;
    font-weight: bold;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 40px;
    max-width: 40px;
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;

    :hover{
        background-color: #fa872d;
        color: black;
    }


`


export const LockButton = styled.button`

    font-size: 20px;
    font-weight: 500;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: #131313;
        background-color: #fa872d;
    }


`

export const StakeManyButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    //color: #fa872d;
    background-color: transparent;
    border: 1px solid ${theme.secondary};
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: #131313;
        background-color: ${theme.secondary};
    }

`



export const RemoveManyButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    //color: #fa872d;
    background-color: transparent;
    border: 1px solid indianred;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: whitesmoke;
        background-color: indianred;
    }

`


export const DaoButton = styled.button`
    
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 10px;
    color: ${theme.textMain};
    background-color: ${theme.secondary};


    :hover{
        background-color: ${theme.secondaryHover};
        transition: background-color 1s;
    }

`


export const SuggestionBtn = styled.button`

    font-size: 14px;
    font-weight: 200;
    color: indianred;
    background-color: transparent;
    border: 1px solid indianred;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;

    :hover{
        color: whitesmoke;
        background-color: indianred;
    }

`

export const FinalizeButton = styled.button`

    font-size: 14px;
    font-weight: 200;
    color: #6bc36b;
    background-color: transparent;
    border: 1px solid #6bc36b;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;

    :hover{
        color: whitesmoke;
        background-color: #6bc36b;
    }

`

export const RequiredIngredientButton = styled.button`

    width: 120px;
    font-size: 14px;
    font-weight: 200;
    color: white;
    background-color: #c30000;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 7px;
    font-family: Roboto;
    font-weight: 900;
    margin-top: 5px;

    :hover{
        color: whitesmoke;
        background-color: #cf2c2cec;
    }

`

export const FungibleTokenButton = styled.a`

    display: inline-block;
    font-size: 14px;
    font-weight: 200;
    color: white;
    background-color: #c30000;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 7px;
    font-family: Roboto;
    font-weight: 900;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    //width: auto;
    justify-content: center;

    :hover{
        color: whitesmoke;
        background-color: #cf2c2cec;
    }

`


export const VestingButton = styled.button`

    font-size: 14px;
    font-weight: 200;
    color: #f3a558;
    background-color: transparent;
    border: 1px solid #f3a558;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;

    :hover{
        cursor: default;
    }

`

export const CloudButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #fc8931;
    //border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: whitesmoke;
        background-color: #e88336;
    }


`

export const AnchorButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #263ec5;
    //border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: whitesmoke;
        background-color: #2d40b0da;
    }


`


export const CancelButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: indianred;
    background-color: transparent;
    border: 1px solid indianred;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: #131313;
        background-color: indianred;
    }


`


export const Remaining = styled.div`

    
    font-size: 12px;
    font-weight: 200;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;
    width: 120px;
    margin-left: auto;
    margin-right: auto;

`


export const MinMint = styled.div`

    
    font-size: 12px;
    font-weight: 200;
    color: #a4a1a1;
    background-color: transparent;
    border: 1px solid #a4a1a1;
    padding: 3px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;

`

export const Price = styled.div`

    
    font-size: 16px;
    font-weight: 200;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 7px;
    margin-top:10px;
    width: 240px;
    margin-left: auto;
    margin-right: auto;

`


export const SocialButton = styled.button`

    font-size: 16px;
    font-weight: 500;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    padding: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    margin-top:10px;
    margin-right: 7px;

    :hover{
        color: #131313;
        background-color: #fa872d;
    }


`


export const LeftProfile = styled.div`

    width: 300px;
    max-width: 300px;
    word-wrap: break-word;
    //margin-right: 50px;

    @media (max-width: 600px) {
        width: 100%;
        max-width: 100%;
    }

`

export const RightProfile = styled.div`

    width: 100%;
    max-width: 100%;
    margin-left: auto;
    align-items: right;
    text-align: right;
    align-content: right;
    justify-content: right;

    @media (max-width: 600px) {
        display: none;
    }
    

`


export const DisabledButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: #696969;
    background-color: transparent;
    border: 1px solid #696969;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;



`

export const DaoButtonCont = styled.div`

    display: flex;
    flex-wrap: wrap;
    gap:5px;
    justify-content: space-between;


`

export const PaymentOptCont = styled.div`

    text-align: center;


`

export const FarmButtonCont = styled.div`

    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    gap:5px;
    justify-content: space-around;
    align-items: center;

    


`

export const DropDown = styled.select`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: #202329;
    border: 1px solid #fa872d;
    width: 80%;
    max-width: 80%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;
    padding-left: 1em;
    
`




export const SubmitButton = styled.button`

    font-size: 18px;
    font-weight: normal;
    color: #696969;
    background-color: transparent;
    border: 1px solid #696969;
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        border: 1px solid ${theme.secondary};
        color: ${theme.secondary};
    }


`