import styled, { keyframes } from 'styled-components';
import { theme } from './Styles'

export const theme2024 = {
    primary: "#8068ab",
    textMain: "white",
    offWhite: '#ebebeb',
    textSecondary: "#BCBCBC",
    textSecondaryDarker: "#9C9999",
    secondaryLight: "#2e2d2d",
    secondaryMedium: "#262525",
    secondaryDark: "#1c1c1b",
    secondaryDarker: "#161616",
    darkGrey: "#343a40",
    hoverColor: "rgba(99, 99, 99, 0.3)",
    backgroundDark: "#0c0d0d",
    secondary: "#D91E75",
    danger: "#dc3545",
    caution: "#ffc107",
    cautionOrange: "#c2810a",
    success: "#28a745",
}

export const PageWrapper2024 = styled.div`
    width: 100vw;
    max-width: 100%;
    min-height: 900px;
    margin-left: 0px; 
    margin-right: 0px;
    margin-top: ${props => props.profile ? '0px' : '70px'};
    background-color: ${theme2024.backgroundDark};
    padding-bottom: 2em;
    padding-top: ${props => props.profile ? '0px' : '50px'};
    color: ${theme2024.textMain};
    word-wrap: break-word;
    overflow: hidden;

    @media (max-width: 900px) {
        height: auto;
    }

`

export const PageBody2024 = styled.div`
    width: ${props => props.fullWidth ? '98%' : '900px'};
    min-height: 900px;
    margin-left: auto;
    margin-right: auto;
    //border-left: 0.5px solid white;
    //border-right: 0.5px solid white;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: 1020px) {
        width: ${props => props.fullWidth ? '98%' : '700px'};
    }

    @media (max-width: 760px) {
        width: ${props => props.fullWidth ? '98%' : '620px'};
    }   
    
    @media (max-width: 630px) {
        width: ${props => props.fullWidth ? '100%' : '98%'};
    }       
`

export const FullWidthPageBody = styled.div`
    width: ${props => props.fullWidth ? '100%' : '900px'};
    min-height: 900px;
    margin-left: auto;
    margin-right: auto;
    //border-left: 0.5px solid white;
    //border-right: 0.5px solid white;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: 1020px) {
        width: ${props => props.fullWidth ? '100%' : '700px'};
    }

    @media (max-width: 760px) {
        width: ${props => props.fullWidth ? '100%' : '620px'};
    }   
    
    @media (max-width: 630px) {
        width: ${props => props.fullWidth ? '100%' : '98%'};
    }       
`

export const Modal2024 = styled.div`
   
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15011;
   
    width: 450px;
    max-width: 90%;
    height: auto;
    max-height: 70%;
    background-color: ${theme2024.secondaryDark};
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 15px;
    //box-shadow: 0px 0px 3px 1px whitesmoke;
    overflow-x: scroll;

    @media (max-width: 900px) {
        margin-top: 50px; 
    }
`

export const ModalContent2024 = styled.div`
    padding-top: 30px;
    color: ${theme2024.textMain};
    font-size: 14px;
    a{
        color: ${theme2024.primary}
    }
    

`

export const ModalErrorCont = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    svg{
        width: 50px;
        height: 50px;
        fill: ${theme2024.danger};
    }
`
export const ModalSuccessCont = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    svg{
        width: 50px;
        height: 50px;
        fill: ${theme2024.success};
    }
`

export const ModalOverlay2024 = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15010;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 80%;
    
`

export const HeaderCont2024 = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;

    h2 {
        color: ${theme2024.primary};
        font-weight: 700;
        font-size: 28px;
        //font-style: italic;
    }

    h3 {
        color: ${theme2024.primary};
        font-weight: 400;
        font-size: 16px;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1em;
    }

    @media (max-width: 760px) {
        flex-wrap: wrap; // Add this line to allow items to wrap onto the next line
        flex-direction: column; // Stack child elements vertically
        align-items: stretch; // Stretch child elements to fill the width

        div {
            text-align: center;
            width: 100%; // Ensure each div takes the full width
            flex-basis: 100%; // Make each div take the full width available
            padding: 0.3em;
        }

        h3 {
            font-weight: 300;
            font-size: 14px;
        }        
    }
`

export const SwapCont = styled.div`
    position: relative;
    width: 420px;
    border: 1px solid #2e2d2d;
    border-radius: 10px;
    height: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #1c1c1b;

    @media (max-width: 480px) {
        width: 98%;
    }     
`

export const SwapTopCont = styled.div`
    position: absolute;
    width: 100%;
    top: 0px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: right;
`

export const SwapFormTitle = styled.div`
    position: absolute;
    top: 5px;
    width: 100%;
    text-align: center;
    color: ${theme2024.primary};
`

export const RightAlignedContainer = styled.div`
    align-self: flex-end;
    //border: 1px solid white;
    padding-top: 4px;
    padding-right: 25px;
`;


export const SwapInputCont = styled.div`
    position: absolute;
    width: 90%;
    //margin-left: auto;
    //margin-right: auto;
    height: 150px;
    border-radius: 7px;
    background-color: #2e2d2d;
    top: 35px;
    left: 5%;
    padding: 15px;
`

export const SwapOutputCont = styled.div`
    position: absolute;
    width: 90%;
    //margin-left: auto;
    //margin-right: auto;
    height: 150px;
    border-radius: 7px;
    background-color: #2e2d2d;
    top: 240px;
    left: 5%;
    padding: 15px;
`

export const SubmitSwapButton = styled.button`
    position: absolute;
    width: 90%;
    //margin-left: auto;
    //margin-right: auto;
    height: 55px;
    border-radius: 7px;
    background-color: #2e2d2d;
    top: 410px;
    left: 5%;
    border: 1px solid #403f3f;
    color: ${theme2024.primary};

    :hover{
        background-color: rgba(99, 99, 99, 0.3);
        transition: background-color 0.3s;
    }    
`
export const SwapRow1 = styled.div`
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    font-weight: 400;
    color: ${theme2024.textSecondary}
`

export const SwapRow2 = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    font-weight: 400;
    color: ${theme2024.textMain}

    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

export const SwapRowContractName = styled.div`
    height: 15px;
    width: 100%;
    text-align: right;
    font-size: 12px;
    font-weight: 300;
    color: ${theme2024.textSecondary}
    opacity: 80%;
`

export const SwapTokenDiv = styled.div`
    width: 30%;
    font-size: 14px;
    text-align: center;
`

export const SwapTokenAmountDiv = styled.div`
    width: 70%;
    font-size: 24px;
    text-align: right;

    @media (max-width: 420px) {
       font-size: 18px;
    }        

    @media (max-width: 375px) {
        font-size: 14px;
    }       

    input{
        background-color: inherit;
        text-align: inherit;
        
        :focus{
            outline: none;
        }
    }
`

export const SwapRow3 = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 300;
    color: ${theme2024.textSecondary}
    padding: 10px;   

    button{
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid ${theme2024.textSecondary};
        border-radius: 5px;
        padding: 5px;
        width: 70px;
        align-items: center;
        height: 100%;
        :hover{
            background-color: rgba(99, 99, 99, 0.3);
            transition: background-color 0.3s;
        }

        @media (max-width: 420px) {
            width: 50px;
            font-size: 10px;
        } 

    }


`

export const SelectTokenModalWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15011;

    width: 420px;
    border: 1px solid #2e2d2d;
    border-radius: 10px;
    height: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #1c1c1b;
    text-align: center;

    @media (max-width: 480px) {
        width: 98%;
    } 
`



export const SelectTokenHeaderCont = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    color: ${theme2024.textMain};
    font-weight: 400;
    padding: 10px;
    text-align: left;
    margin-bottom: 15px;
    //border-bottom: 1px solid ${theme2024.textSecondary};
`

export const SelectTokenRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    //border-bottom: 1px solid ${theme2024.textSecondary};
    font-size: 18px;

    img{
        width: 25px;
        height: 25px;
    }

    button{
        display: inherit;
        flex-wrap: wrap;
        //flex-direction: column;
        align-items: center;
        //justify-content: space-between;
        padding: 12px;
        width:100%;
        :hover{
            background-color: rgba(99, 99, 99, 0.3);
            transition: background-color 0.3s;
        }
    }
`
export const SelectTokenRowTokenName = styled.div`
    padding-left: 7px;
    font-size: 16px;
    font-weight: 300;
    //border: 1px solid green;

    span{
        color: ${theme2024.textSecondary};
        font-size: 12px;
        font-weight: 400;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    } 

`

export const SelectTokenRowTokenAmount = styled.div`
    //width: 100%;
    flex-grow: 1;
    text-align: right;
    //border: 1px solid white;
    font-size: 18px;
    color: ${theme2024.textSecondary};
    //border: 1px solid green;

    @media (max-width: 480px) {
        font-size: 14px;
    }     
`

export const SelectLpTokenUnderlyingInfo = styled.div`
    width: 100%;
    font-size: 12px;
    text-align: left;
    padding-left: 10px;
`

export const SelectTokenModalInnerWrapper = styled.div`
   
   
    width: 100%;
    max-width: 100%;
    height: 80%;
    max-height: 80%;
    background-color: #1c1c1b;
    text-align: left;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    overflow-x: hidden;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme2024.primary} black; 

    ::-webkit-scrollbar{
        height: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme2024.primary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }

    a{
        color: ${theme2024.primary};
    }

`

export const TokenTrackerPageButton = styled.button`
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: ${theme2024.textMain};
    background-color: ${props => props.selected ? theme2024.primary : 'transparent'}
    :hover{
        background-color: ${props => props.selected ? '' : theme2024.hoverColor};
        transition: background-color 0.3s;
    }
`

export const FoldersContainer2024 = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    clear: both;
`
export const FolderTab2024 = styled.button`
    display: inline-block;
    width: 175px;
    border-bottom: ${props => props.selected == true ? `2px solid ${theme2024.primary}` : 'none'};
    height: 30px;
    color: ${props => props.selected == true ? theme2024.primary : theme2024.textSecondary };
    font-weight: 500;

    :hover{
        color: ${theme2024.textMain};
    }

    @media (max-width: 768px) {
        width: 25%;
    }
`

//Homepage

export const HomepageWrapper2024 = styled.div`
    width: 100vw;
    max-width: 100%;
    min-height: 900px;
    margin-left: 0px; 
    margin-right: 0px;
    margin-top: 0px;
    background-color: ${theme2024.backgroundDark};
    padding-bottom: 2em;
    padding-top: 0px;
    color: ${theme2024.textMain};
    word-wrap: break-word;
    overflow: hidden;

    @media (max-width: 900px) {
        height: auto;
    }

`

export const HomepageNotification = styled.div`
    width: 100%;
    background-color: ${theme2024.primary};
    color: ${theme2024.textMain};
    font-size: 12px;
    padding: 0.3em;
    margin-top: 110px;
    text-align: center;
`

export const HeroWrapper2024 = styled.div`
    width: 100%;
    display: flex;
    //border: 1px solid orange;
    height: 530px;

    @media (max-width: 940px) {
        height: auto;
        flex-direction: column;
    }     

    @media (max-width: 1020px) {
        justify-content: space-between;
    }    
`

export const HeroLeft2024 = styled.div`
    height: 100%;
    width: 60%;
    //border: 1px solid green;

    @media (max-width: 1020px) {

    }  

    @media (max-width: 940px) {
        width: 100%;
        height: 480px;
    } 
   
`

export const HeroLeftTop2024 = styled.div`
    width: 100%;
    height: 40%;
    //border: 1px solid magenta;
    font-size: 72px;
    font-weight: 700;
    display: flex;
    align-items: flex-end; 
    color: ${theme2024.textMain};
    padding-left: 0.5em;
    padding-bottom: 0px;

    @media (max-width: 940px) {
        justify-content: center;
        padding-left: 0px;
      }     
      
      @media (max-width: 560px) {
        font-size: 60px;
    }     
    
    @media (max-width: 480px) {
        font-size: 48px;
      }    
`


export const HeroLeftMiddle2024 = styled.div`
    width: 100%;
    height: 20%;
    //border: 1px solid magenta;
    font-size: 72px;
    font-weight: 700;
    color: ${theme2024.primary};
    padding-left: 0.5em;
    overflow: hidden; /* Hide the text when it's outside the box */
    white-space: nowrap; /* Prevent text from wrapping */   
    
    @media (max-width: 940px) {
        padding-left: 0px;
      }      

    @media (max-width: 560px) {
        font-size: 60px;
    }    
    
    @media (max-width: 480px) {
        font-size: 48px;
      }    

`;


export const HeroLeftAnimation = styled.div`
    //border: 1px solid green;
    padding-top: 0px;
    line-height: 62px;
    animation: textAnimation 4s infinite;
        
    @keyframes textAnimation {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }
        15% {
            transform: translateX(0%);
            opacity: 1;
        }
        40% {
            transform: translateX(0%);
            opacity: 1;
        }
        55% {
            transform: translateX(0%);
            opacity: 1;
        }
        70% {
            transform: translateX(0%);
            opacity: 1;
        } 
        85% {
            transform: translateX(0%);
            opacity: 1;
        }         
        100% {
            transform: translateX(-100%);
            opacity: 0;
        }
    }

    @media (max-width: 940px) {
        justify-content: center;
        text-align: center;
      }          
`

export const HeroLeftBottom2024 = styled.div`
    display: flex;
    gap: 15px;
    width: 100%;
    height: 20%;
    //border: 1px solid orange;
    color: ${theme2024.textSecondary};
    font-size: 16px;
    font-weight: 400;
    padding: 1em;
    padding-left: 2em;

    @media (max-width: 940px) {
        text-align: center;
        justify-content: center;
        padding-right: 2em;
      }      
`

export const Hero2024Button = styled.button`
    width: 130px;
    padding: 1em;
    border: 1px solid ${theme2024.textSecondary};
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme2024.textMain};
    background-color: ${props => props.primary ? theme2024.primary : 'transparent'};
    height: 50px;

    :hover{
        border: 1px solid ${theme2024.textMain};
        padding: 1.1em;
        transform: scale(1.05);
        transition: transform 0.2s;
    }

    @media (max-width: 480px) {
        font-size: 12px;
        width: 100px;
      }     
      
      @media (max-width: 430px) {
        margin-top: 20px;
  }        

`

export const HeroRight2024 = styled.div`
    height: 100%;
    width: 40%;
   //border: 1px solid green;

    @media (max-width: 1020px) {
        width: 355px;
    } 

    @media (max-width: 940px) {
        width: 100%;
        height: 500px;
        display: flex;
        flex-direction: column;
    }           
`

const glowAnimation = keyframes`
0% {
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.5), 0 0 4px rgba(255, 255, 255, 0.7);
  }
  50% {
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.7), 0 0 8px rgba(255, 255, 255, 0.9);
  }
  100% {
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.5), 0 0 4px rgba(255, 255, 255, 0.7);
  }
`;

export const ProductOfWeekWrapper = styled.div`
    background-color: ${theme2024.secondaryDark};
    height: 85%;
    width: 325px;
    margin: auto;
    margin-top: 60px;
    border-radius: 15px;
    position: relative;
    //animation: ${glowAnimation} 3s ease infinite;
    box-shadow: 0 0 4px 1px ${theme2024.primary};   

    h2{
        position: absolute;
        top: 230px;
        //border: 1px solid white;
        font-weight: 400;
        font-size: 14px;
        color: ${theme2024.textSecondary};
        text-align: left;
        width: 100%;
        padding-left: 14px;
    }

    h3{
        position: absolute;
        top: 250px;
        //border: 1px solid white;
        font-weight: 600;
        font-size: 22px;
        color: ${theme2024.primary};
        text-align: left;
        width: 100%;
        padding-left: 14px;
    }    

    img{
        position: absolute;
        object-fit: cover;
        top: 0px;
        width: 100%;
        height: 215px;
        border-radius: 15px 15px 0px 0px;
    }

    p{
        position: absolute;
        top: 300px;
        //border: 1px solid white;
        font-weight: 400;
        font-size: 12px;
        color: ${theme2024.textSecondary};
        text-align: left;
        width: 100%;
        padding-left: 14px;
        padding-right: 14px;        
    }

    @media (max-width: 1020px) {
        margin-left: 0px;
    }  
    
    @media (max-width: 940px) {
        margin-left: auto;
        margin-top: 20px;
        height: 450px;
    }     
`

export const ProductOfWeekButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    //border: 1px solid dimgrey;
    height: 60px;
    position: absolute;
    top: 370px;
    padding-left: 14px;
    gap: 15px;
`

export const Homepage2024Section2 = styled.div`
    width: 100%;
    //display: flex;
    //border: 1px solid dimgrey;
    height: auto;
    padding-top: 30px;

    

    h2{
        width: 100%;
        text-align: center;
        color: ${theme2024.primary};
        font-weight: 600;
        font-size: 16px;
        //border: 1px solid purple;
    }

    h3{
        //border: 1px solid green;
        width: 100%;
        text-align: center;
        color: ${theme2024.textMain};
        font-weight: 600;
        font-size: 36px;
        margin-top: 20px;
    }      

    @media (max-width: 1020px) {
    
    }   
    
    @media (max-width: 940px) {
        display: flex;
        flex-direction: column;
        clear: both;
        overflow: hidden;
        padding-left: 2em;
        padding-right: 2em;
    }     
`

export const HomeSwapFormWrapper = styled.div`
    width: 50%;
    display: inline-block;

    @media (max-width: 940px) {
        width: 100%;
    }      
`

export const Section2BottomWrapper = styled.div`
    width: 100%;
    height: 400px;
    //border: 1px solid orange;
    display: flex;

    div{
        display: inline-block;
        width: 50%;
        //border-right: 1px solid dimgrey;
        height: 100%;
    }

    p{
        padding-left: 60px;
        padding-right: 15px;
        margin-top: 1em;
        font-size: 18px;
    }
`

export const HomeSwapWrapper = styled.div`
    display: inline-block;
    width: 100%;
`

export const HomeSwapNeighbor = styled.div`
    display: inline-block;
    width: 50%;
    //border: 1px solid white;
    vertical-align: top;
    padding-top: 2em;

    div{
        //border: 1px solid white;
        height: 400px;
    }

    p{
        padding-left: 60px;
        padding-right: 15px;
        margin-top: 1em;
        font-size: 18px;
    }

    @media (max-width: 940px) {
        width: 100%;;
        display: flex;
        flex-direction: column;

        div{
            //border: 1px solid white;
            height: auto;
            padding-bottom: 2em;
        }

        p{
            padding-left: 1em;
            padding-right: 1em;
            text-align: center
        }        
    }     
`

export const HomepageKeyStatsSection = styled.div`
    width: 100%;
    padding-top: 30px;

    h2{
        width: 100%;
        text-align: center;
        color: ${theme2024.primary};
        font-weight: 600;
        font-size: 16px;
        //border: 1px solid purple;
    }    
`

export const KeyStatsWrapper = styled.div`
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 15px;
`

export const KeyStatBox = styled.div`
    width: 200px;
    height: 100px;
    //border-radius: 10px;
    //border: 1px solid ${theme2024.secondaryMedium};
    text-align: left;

    h3{
        text-align: left;
        width: 100%;
        font-weight: 600;
        color: ${theme2024.primary};
        font-size: 16px;
        letter-spacing: 3px;
    }

    h4{
        color: ${theme2024.textMain};
        font-size: 32px;
        font-weight: 600;
        width: 100%;
        text-align: left;
        margin-top: 0px;
    }

    @media (max-width: 480px) {
        width: 140px;
        padding-right: 0px;
        height: 60px;

        h3{
            font-size: 12px;
            letter-spacing: 2px;
        }
    
        h4{
            font-size: 24px;
        }        
  }  
`

export const InfoIsLoadingBlur = styled.div`
@keyframes loadingAnimation {
    0% {
      background-position: -150px 0;
    }
    40% {
        background-position: 150px 0;
      }    
    100% {
      background-position: 150px 0;
    }
  }

  width: 150px;
  height: 12px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.3));
  background-size: 150px 20px;
  animation: loadingAnimation 2s linear infinite;

`

export const CreatorToolsWrapper = styled.div`
  width: 100%;
  padding-top: 30px;

  h2{
      width: 100%;
      text-align: center;
      color: ${theme2024.primary};
      font-weight: 600;
      font-size: 16px;
      //border: 1px solid purple;
  }

  p{
    width: 100%;
    padding: 1em;
    font-size: 20px;
    text-align: center;
  }

  a{
    height: auto;
    display: flex;
  }

`

export const CreatorToolBox = styled.div`
    width: 200px;
    min-height: 120px;
    height: auto;
    //border-radius: 10px;
    border: 1px solid ${theme2024.secondaryMedium};
    text-align: left;

    h3{
        text-align: left;
        width: 100%;
        font-weight: 600;
        color: ${theme2024.primary};
        font-size: 16px;
        letter-spacing: 3px;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 14px;
    }

    p{
        color: ${theme2024.textMain};
        font-size: 14px;
        width: 100%;
        text-align: left;
        margin-top: 0px;
        //border: 1px solid yellow;
        padding-left: 14px;
        padding-right: 14px;
        padding-bottom: 14px;
    }

    :hover{
        border: 1px solid ${theme2024.textMain};
        transform: scale(1.05);
        transition: transform 0.2s;
    }

    @media (max-width: 480px) {
        width: 154px;

        h3{
            font-size: 12px;
            letter-spacing: 1px;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 12px;            
        }
    
        p{
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 12px; 
        }
  }     

`

export const RoadmapWrapper = styled.div`
  width: 100%;
  padding-top: 30px;

  h2{
      width: 100%;
      text-align: center;
      color: ${theme2024.primary};
      font-weight: 600;
      font-size: 16px;
      //border: 1px solid purple;
  }

`

export const RoadmapInnerWrapper = styled.div`
  margin-top: 30px;
  width: 90%;
  border: 1px solid ${theme2024.primary};
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
`

export const RoadmapLeft = styled.div`
  display: inline-block;
  width: 66%;
  text-align: left;
  padding: 2em;
  color: ${theme2024.textMain};
  font-size: 18px;

  @media (max-width: 680px) {
    width: 100%;
  }
`

export const RoadmapRight = styled.div`
  display: inline-block;
  width: 34%;
  text-align: left;
  padding-top: 2em;
  color: ${theme2024.textMain};
  font-size: 16px;
  font-weight: 600;
  vertical-align: top;

  @media (max-width: 680px) {
    width: 100%;
    padding-left: 2em;
  }  
`

export const RoadmapRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  //border: 1px solid dimgrey;
`;

export const CheckboxContainer = styled.div`
  margin-right: 1em;

  img{
    background-color: ${theme2024.primary};
    width: 20px;
    height: 20px;
  }

  div{
    width: 20px;
    height: 20px;
    border: 1px solid ${theme2024.textMain};
  }
`;

export const GoalContainer = styled.div`
  flex-grow: 1;
`;

export const HomepageSwapOutputCont = styled.div`
    position: absolute;
    width: 90%;
    //margin-left: auto;
    //margin-right: auto;
    height: 150px;
    border-radius: 7px;
    background-color: #2e2d2d;
    top: 190px;
    left: 5%;
    padding: 15px;
`

export const HomepageSubmitSwapButton = styled.button`
    position: absolute;
    width: 90%;
    //margin-left: auto;
    //margin-right: auto;
    height: 40px;
    border-radius: 7px;
    background-color: #2e2d2d;
    top: 410px;
    left: 5%;
    border: 1px solid #403f3f;
    color: ${theme2024.primary};

    :hover{
        background-color: rgba(99, 99, 99, 0.3);
        transition: background-color 0.3s;
    }    
`

export const PoweredByAlcor = styled.div`
    position: absolute;
    width: 100%;
    top: 450px;
    font-size: 12px;
    display: flex;
    font-style: italic;
    color: ${theme2024.textSecondary};
    justify-content: center;
    padding-top: 6px;
    font-weight: 600;

    img{
        height: 18px;
        width: auto;
        margin-left: 5px;
    }
`

export const SwapDetailsCont = styled.div`
    position: absolute;
    //border: 1px solid white;
    top: 340px;
    height: 70px;
    width: 90%;
    left: 5%;
    font-size: 12px;
    padding-top: 10px;
`

export const SwapDetailsRow = styled.div`
    display: flex;
    width: 100%;
`
export const SwapDetailsName = styled.div`
    width: ${props => props.row == "Rate" ? "20%" : "40%"};
    color: ${theme2024.textSecondary};
    text-align: left;
`
export const SwapDetailsValue = styled.div`
    width: ${props => props.row == "Rate" ? "80%" : "60%"};
    color: ${theme2024.textMain};
    display: flex;
    justify-content: right;
`

export const CreateLockButton = styled.button`
    width: 90%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    height: 55px;
    border-radius: 7px;
    background-color: #2e2d2d;
    border: 1px solid #403f3f;
    color: ${theme2024.primary};
    margin-top: 15px;
    transition: background-color 0.5s;

    :hover{
        background-color: rgba(99, 99, 99, 0.5);
        
    }    
`

export const MyLocksWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    padding-top: 20px;
    row-gap: 20px;
    gap: 15px;
`

export const LpLockCard2024 = styled.div`
    position: relative;
    width: 160px;
    height: 180px;
    border-radius: 10px;
    background-color: #101010; //${theme2024.secondaryDarker};
    box-shadow: 1px 1px 3px 0px ${theme2024.secondaryLight};

    @media (max-width: 420px) {
        width: 140px;
        height: 160px;
      }      

    :hover{
        cursor: pointer;
        transform: scale(1.05);
        transition: transform 0.2s;
        //border: 1px solid white;
        box-shadow: 0px 0px 3px 0px ${theme2024.textMain};
    }
`

export const LpLockPicCont = styled.div`
    position: absolute;
    top: 0px;
    width: 100%;
    height: 110px;
    text-align: center;
    padding-top: 10px;
    //border: 1px solid white;
    background-color: ${theme2024.secondaryDarker};
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid ${theme2024.secondaryMedium};

    img{
        width: auto;
        max-width: 70%;
        height: auto;
        max-height: 90px;
        margin-left: auto;
        margin-right: auto;
        opacity: 80%;

        @media (max-width: 420px) {
            max-height: 70px;
          }         
    }

    @media (max-width: 420px) {
        height: 90px;
      }     
`

export const LpLockCardAmount = styled.div`
    position: absolute;
    top: 115px;
    width: 100%;
    padding-left: 10px;
    font-weight: 600;
    font-size: 12px;
    color: ${theme2024.primary};
    text-align: left;

    @media (max-width: 420px) {
        top: 95px;
      }      
`

export const LpLockCardIngredients = styled.div`
    position: absolute;
    top: 132px;
    width: 100%;
    padding-left: 10px;
    font-weight: 400;
    font-size: 10px;
    color: ${theme2024.textSecondary};
    text-align: left;

    @media (max-width: 420px) {
        top: 112px;
      }      
`
export const LpLockCardDate = styled.div`
    position: absolute;
    top: 156px;
    width: 100%;
    font-size: 10px;
    text-align: left;
    padding-left: 10px;
    vertical-align: middle;
    //border: 1px solid green;
    line-height: 18px;

    display: flex;
    p{
        display: inline-block;
        padding-left: 5px;
        font-weight: 600;
        color: ${theme2024.textSecondary};
        //border: 1px solid yellow;
    }

    svg{
        height: 14px;
        width: auto;
        display: inline-block;
        fill: ${theme2024.textSecondary};
    }

    @media (max-width: 420px) {
        top: 136px;
      }      
`

export const HighlightsWrapper = styled.div`
      width: 100%;
      height: 120px;
      display: flex;
      margin-top: 15px;
      justify-content: space-around;
      justify-items: center;
      //border: 1px solid ${theme2024.darkGrey};



    @media (max-width: 1320px) {
        height: 108px;
    } 

    @media (max-width: 900px) {
        height: 108px;
    }     
`

export const SingleHighlightWrapper = styled.div`
      width: 400px;
      height: 100%;
      //border: 1px solid yellow;
      position: relative;
      

        @media (max-width: 1320px) {
            width: 360px;
        }

        @media (max-width: 1180px) {
            display: ${props => props.hide && 'none'};
        }         

        @media (max-width: 900px) {
            width: 360px;
        }  
        
        @media (max-width: 800px) {
            display: ${props => !props.show ? 'none' : 'flex'};
        }        

      img, video{
        object-fit: cover;
        z-index: 1000;
        border-radius: 20px;
        width: 100%;
        max-width: 100%
        height: 100%;
        max-height: 100%;
      }
`