import { useState } from "react";
import { currentApiList, getCurrentApiList } from "../../data/config";
import axios from 'axios';

export const useGetDynamicTemplates = () => {
    
    const [templates, setTemplates] = useState([]);

    const getDynamicTemplates = (farmname, farmtype) => {
        
        if(farmtype == "template"){
            axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
                table:"farmtmplates",
                scope:farmname,
                code:"waxdaofarmer",
                limit:1000,
                json:true
                }).then((res) => {
                    if(res.data.rows.length > 0){
                        setTemplates(res.data.rows);
                        console.log(res.data.rows);
                    }
                })
                .catch((error) => console.log(error));
        }
        else if(farmtype == "nc.v2"){
            axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
                table:"nctemplates",
                scope:farmname,
                code:"waxdaofarmer",
                limit:1000,
                json:true
                }).then((res) => {
                    if(res.data.rows.length > 0){
                        setTemplates(res.data.rows);
                        console.log(res.data.rows);
                    }
                })
                .catch((error) => console.log(error));   
            }
         

    }

    return [templates, getDynamicTemplates]
}