import styled from 'styled-components'
import { theme } from '../../Styles'
import { theme2024 } from '../../Styles2024'

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

export const FarmsAndDropsCont = styled.div`
    width: 100vw;
    max-width: 100%;
    min-height: 900px;
    margin-left: 0px; 
    margin-right: 0px;
    background-color: ${theme.main};
    padding-bottom: 2em;
    padding-top: 50px;
    color: ${theme.textMain};
    word-wrap: break-word;

    @media (max-width: 900px) {
        height: auto;
    }

`

export const CollectionProfileContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 300px;
    margin-top: 110px;

    @media (max-width: 768px) {
        height: 240px;
    }

    @media (max-width: 500px) {
        height: 200px;
    }
`
export const CollectionProfileBGContainer = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
`

export const CollectionProfileAvatarCont = styled.div`
    position: absolute;
    top: 230px;
    left: 25px;
    width: 100px;
    max-width: 100px;
    height: 100px;
    max-height: 100px;
    border: 5px solid ${theme2024.primary};
    overflow: hidden;
    border-radius: 15px;
    background-color: ${theme2024.secondaryDark};

    @media (max-width: 768px) {
        top: 170px;
    }

    @media (max-width: 500px) {
        top: 130px;
    }
`

export const MilestoneThird = styled.div`
    display: inline-block;
    width: 33%;
    text-align: left;
    font-size: 14px;
    color: ${theme.textSecondary};

    @media (max-width: 725px) {
        width: 50%;
        padding-top: 6px;
        padding-bottom: 6px;
    }
`
export const MilestoneBoldWhite = styled.span`
    color: ${theme.textMain};
    font-weight: 900;
`
