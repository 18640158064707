import React from 'react';
import { ModalContent } from './LeftDiv';
import { SelectTokenHeaderCont, SelectTokenModalInnerWrapper, SelectTokenModalWrapper, SelectTokenRow, SelectTokenRowTokenAmount, SelectTokenRowTokenName } from '../Styles2024';

const SelectTokenModal = (props) => {
    const showAttributeModal = props.showAttributeModal;
    const setShowAttributeModal = props.setShowAttributeModal;
    const tokens = props.tokens;
    const selectedToken = props.selectedToken;
    const setSelectedToken = props.setSelectedToken;
    const location = props.location;

    return (
        <SelectTokenModalWrapper className={showAttributeModal}>
            <SelectTokenHeaderCont>
                <div>Select A Token</div>
                <div style={{ paddingRight: "5px", fontWeight: "300" }}>
                    <button onClick={() => setShowAttributeModal(false)}>
                        X
                    </button>
                </div>
            </SelectTokenHeaderCont>
            <SelectTokenModalInnerWrapper>
                <ModalContent>
                    {
                        ((location === "migrate" || location === "ads") && tokens && tokens.length > 0) ? (
                            tokens
                                .filter(t => 
                                    location === "migrate" ?
                                    t.contract === "mdcryptonfts" && (t.currency === "WAXDAO" || t.currency === "WOJAK")
                                    :
                                    t.contract === "token.waxdao" || t.contract === "eosio.token"
                                    )

                                .sort((a,b) => b.amount - a.amount)
                                .map((t, index) => (
                                    <SelectTokenRow key={index}>
                                        <button
                                            onClick={() => {
                                                setSelectedToken(t)
                                                setShowAttributeModal(false)
                                            }}
                                        >
                                            <img
                                                src={`https://logos.waxdao.io/${t.currency.toLowerCase()}_${t.contract}.png`}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = "https://logos.waxdao.io/default-logo.png";
                                                }}
                                            />

                                            <SelectTokenRowTokenName>
                                                {t.currency}
                                            </SelectTokenRowTokenName>

                                            <SelectTokenRowTokenAmount>
                                                {t.amount}
                                            </SelectTokenRowTokenAmount>

                                        </button>
                                    </SelectTokenRow>
                                ))
                        ) : (
                            tokens && tokens.length > 0 ? (
                                tokens
                                .filter(t => t.amount > 0)
                                .sort((a,b) => b.amount - a.amount)
                                .map((t, index) => (
                                    <SelectTokenRow key={index}>
                                        <button
                                            onClick={() => {
                                                setSelectedToken(t)
                                                setShowAttributeModal(false)
                                            }}
                                        >
                                            <img
                                                src={`https://logos.waxdao.io/${t.currency.toLowerCase()}_${t.contract}.png`}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = "https://logos.waxdao.io/default-logo.png";
                                                }}
                                            />

                                            <SelectTokenRowTokenName>
                                                {t.currency}
                                            </SelectTokenRowTokenName>

                                            <SelectTokenRowTokenAmount>
                                                {t.amount}
                                            </SelectTokenRowTokenAmount>

                                        </button>
                                    </SelectTokenRow>
                                ))
                            ) : (
                                <span>No tokens available</span>
                            )
                        )
                    }
                </ModalContent>
            </SelectTokenModalInnerWrapper>
        </SelectTokenModalWrapper>
    )
}

export default SelectTokenModal;
