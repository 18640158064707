import React, { useState } from 'react'
import { handleAddAsset, handleRemoveAsset } from '../data/functions/premint_functions';
import { currentWebsiteURL } from '../data/config';
import { buildNftImage2024, collectionIsNsfw, showNsfwOverlay } from '../data/functions/global_functions';
import { hasNsfwHidden } from '../pages/Settings';
import { CollectionNameWrapper2024, NftCardIconsWrapper, NftCardMint2024, NftCardNameWrapper, NftCardShowDetailsLeftSide, NftCardShowDetailsRightSide, NftCardShowDetailsWrapper, NftCardWrapper2024, NftcardTopHalf2024 } from '../data/css/NftStyles2024';
import { ModalOverlay2024, theme2024 } from '../Styles2024';
import LoadingSpinnerImageFallback from './LoadingSpinnerImageFallback';
import { backed_tokens_svg, checked_svg, select_svg, transfer_icon, whitelist_svg } from '../data/svgs';
import AttributeModal2024 from '../pages/WalletComponents/AttributeModal2024';
import DetailsModal from './DetailsModal';

const handleLinkClick = (location, event) => {
  if (location === 'asset_page') {
    event.preventDefault();
  }
};

const showNftIcons = (nft, backedData, setShowDetailsModal, setDetailToShow) => {
  let buttons = [];
  let collection = '';
  let schema = '';
  let template_id = '';

  if(nft?.collection?.collection_name){
    collection = nft?.collection?.collection_name;
  }

  if(nft?.schema?.schema_name){
    schema = nft.schema.schema_name;
  }  

  let isAWojak = collection == 'hoodpunknfts' && schema == 'waxywojaks';

  if(isAWojak){
    buttons.push(<button key={`${nft.asset_id}-backed-tokens-button`}
      onClick={(e) => {
        setDetailToShow("backed tokens")
        setShowDetailsModal(true);
      }}
    >{backed_tokens_svg}</button>)
    buttons.push(<button key={`${nft.asset_id}-whitelist-button`}
      onClick={(e) => {
        setDetailToShow("whitelist")
        setShowDetailsModal(true);
      }}
    >{whitelist_svg}</button>)
  }  

  if(!isAWojak && (nft?.backed_tokens?.length > 0 || backedData?.findIndex(backed => backed.asset_id == nft.asset_id) > -1)){
    buttons.push(<button key={`${nft.asset_id}-backed-tokens-button`}
      onClick={(e) => {
        setDetailToShow("backed tokens")
        setShowDetailsModal(true);
      }}
    >{backed_tokens_svg}</button>)
  }

  return <NftCardIconsWrapper>
    {buttons}
</NftCardIconsWrapper>

}

const DaoNftCard = (props) => {
    const item = props.item;
    const assetVector = props.assetVector ? props.assetVector : [];
    const setAssetVector = props.setAssetVector ? props.setAssetVector : () => {return};
    const nsfwCollections= props.nsfwCollections;
    const nsfwIsLoading = props.nsfwIsLoading;
    const backedData = props.backedData;
    let location = props.location;
    if(!location){
      location = ''
    }

    const wharfSession=  props.wharfSession;
    const enterModalText= props.enterModalText;
    const enterModalDisplay= props.enterModalDisplay;
    const loadingDisplay= props.loadingDisplay;
    const setEnterModalText= props.setEnterModalText;
    const setEnterModalDisplay= props.setEnterModalDisplay;
    const setLoadingDisplay= props.setLoadingDisplay;

    const [showAttributeModal, setShowAttributeModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [detailToShow, setDetailToShow] = useState("");

  return (
    <NftCardWrapper2024 selected={assetVector.indexOf(item.asset_id) > -1}>
        <NftcardTopHalf2024 location={location}>
          {(nsfwIsLoading || isLoading)&& <LoadingSpinnerImageFallback />}

        {!nsfwIsLoading && (
          <a onClick={(e) => handleLinkClick(location, e)} href={`${currentWebsiteURL}/asset/${item.asset_id}`}>
        {collectionIsNsfw(item.collection.collection_name, nsfwCollections) && hasNsfwHidden() ? 
        showNsfwOverlay() : 
        buildNftImage2024(item.data, setIsLoading)}
          </a>
        )} 
        </NftcardTopHalf2024>
        
        {showAttributeModal && (
            <span>
                <ModalOverlay2024 />
                <AttributeModal2024 showAttributeModal={showAttributeModal} setShowAttributeModal={setShowAttributeModal} nft={item} />
            </span>
        )}


      {item.collection != null && (
          <a href={`${currentWebsiteURL}/collection/${item.collection.collection_name}`}>
        <CollectionNameWrapper2024>
        {item.collection.collection_name}
        </CollectionNameWrapper2024>
        </a>
      )}

        <NftCardNameWrapper>
        {item.data.name != null && (
            <>
              <h2>
                {item.data.name.length > 10 ? `${item.data.name.substring(0,7).trim()}...` : item.data.name}
                </h2>
             {item.template_mint != null && ( 
               <NftCardMint2024 small={item.template_mint.length > 6}>
                 #{item.template_mint}
               </NftCardMint2024>
                 )}             
            </>
        )}
        </NftCardNameWrapper>

      {showNftIcons(item, backedData, setShowDetailsModal, setDetailToShow)}
      
      {showDetailsModal && (
            <span>
                <ModalOverlay2024 />
                <DetailsModal 
                  detailToShow={detailToShow} 
                  setShowDetailsModal={setShowDetailsModal} 
                  nft={item} 
                  location={location}
                  backedData={backedData}
                  wharfSession={wharfSession}
                  enterModalText={enterModalText}
                  enterModalDisplay={enterModalDisplay}
                  loadingDisplay={loadingDisplay}
                  setEnterModalText={setEnterModalText}
                  setEnterModalDisplay={setEnterModalDisplay}
                  setLoadingDisplay={setLoadingDisplay}                  
                />
            </span>
        )}

      <NftCardShowDetailsWrapper>
        <NftCardShowDetailsLeftSide onClick={() => setShowAttributeModal(true)}>
          DETAILS
        </NftCardShowDetailsLeftSide>
        <NftCardShowDetailsRightSide selected={assetVector.indexOf(item.asset_id) > -1} onClick={() => {assetVector.indexOf(item.asset_id) > -1 ? handleRemoveAsset(item.asset_id, assetVector, setAssetVector) : handleAddAsset(item.asset_id, assetVector, setAssetVector)}}>
        {assetVector.indexOf(item.asset_id) > -1 ? checked_svg : select_svg}
        </NftCardShowDetailsRightSide> 

      </NftCardShowDetailsWrapper>

    </NftCardWrapper2024>
  )
}

export default DaoNftCard