import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { Body, MainWrapper } from './Styles';
import { useStateContext } from './contexts/ContextProvider';
import { WalletContextProvider } from './contexts/WalletContextProvider';
import { Footer } from './components';
import LoadingPage from './components/LoadingPage';
import { sessionKit } from './data/wharfkit';
import { GlobalStyle } from './Styles';
import Navbar2024 from './components/Navbar2024';

//Need to all be lazy loaded
import { FarmingNav, WojakNav, TokenNav, Escrow, MyEscrow } from './pages';
import PackTutorial from './pages/PackTutorial';
import NotFound from './components/NotFound';
import AdTerms from './components/AdTerms';
import WaxLabs from './components/WaxLabs';
import WaxLabs2 from './components/WaxLabs2';
import Create from './pages/Navigation/Create';
import Locker from './pages/locker/Locker';
import ManageLocks from './pages/locker/ManageLocks';
import LockPage from './pages/locker/LockPage';
import GuildCandidacy from './pages/GuildCandidacy';
import DAOFAQV2 from './pages/daosv2/DAOFAQV2';
import CreateFarmV2 from './pages/farmsv2/CreateV2Farm';
import EditV2Farm from './pages/farmsv2/EditV2Farm';
import FarmFAQV2 from './pages/farmsv2/FarmFAQV2';
import RemoveContract from './pages/RemoveContract';
import NotificationModal from './components/NotificationModal';
import Settings from './pages/Settings';



//General
const HomePage2024 = lazy(() => import ('./pages/HomePage2024'));
const WalletPage = lazy(() => import("./pages/WalletPage"));
const Wallet2024 = lazy(() => import("./pages/utilities/Wallet2024"));
const AssetIdPage = lazy(() => import('./pages/utilities/AssetIdPage'));

//Farms
const FarmPageV2 = lazy(() => import('./pages/farmsv2/FarmPageV2'));
const CreateFarm = lazy(() => import("./pages/CreateFarm"));
const FarmPage = lazy(() => import("./pages/FarmPage"));
const Farms2024 = lazy(() => import("./pages/farmsv2/Farms2024"));
const UserFarms = lazy(() => import('./pages/UserFarms'));
const NFTsStakedByUserInFarm = lazy(() => import('./pages/NFTsStakedByUserInFarm'));
const FarmOptions = lazy(() => import('./pages/FarmOptions'));
const Stakers = lazy(() => import('./pages/farmsv2/Stakers'));


//Pools
const Pools = lazy(() => import("./pages/pools/Pools"));
const CreatePool = lazy(() => import("./pages/pools/CreatePool"));
const PoolPage = lazy(() => import("./pages/pools/PoolPage"));
const PoolOptions = lazy(() => import("./pages/pools/PoolOptions"))

//drops
const CreatePremint = lazy(() => import("./pages/drops/CreatePremint"));
const PremintPage = lazy(() => import("./pages/drops/PremintPage"));
const CreatePack = lazy(() => import("./pages/drops/CreatePack"));
const UnboxPage = lazy(() => import("./pages/drops/UnboxPage"));
const CollectionDrops = lazy(() => import("./pages/drops/CollectionDrops"));
const Drops = lazy(() => import("./pages/drops/Drops"));
const EditPack = lazy(() => import('./pages/drops/EditPack'));
const MyDrops = lazy(() => import('./pages/drops/MyDrops'))
const EditDrop = lazy(() => import('./pages/drops/EditDrop'));
const CreateDrop = lazy(() => import('./pages/drops/CreateDrop'));
const DropPage = lazy(() => import('./pages/drops/DropPage'));
const MyPremintedPools = lazy(() => import('./pages/drops/MyPremintedPools'));


//blends
const BlendPage = lazy(() => import("./pages/blends/BlendPage"));
const CreateBlend = lazy(() => import("./pages/blends/CreateBlend"));
const Blends = lazy(() => import("./pages/blends/Blends"));
const TokenCreator = lazy(() => import("./pages/TokenCreator"));
const UserPage = lazy(() => import('./pages/UserPage')) ;
const CollectionBlends = lazy(() => import('./pages/blends/CollectionBlends'));
const EditBlend = lazy(() => import('./pages/blends/EditBlend'));

//Tokens
const AllTokens2024 = lazy(() => import("./pages/token_tracker/AllTokens2024"));
const TokenPage = lazy(() => import('./pages/token_tracker/TokenPage'));
const Migrate = lazy(() => import('./pages/Migrate'));
const SwapPage = lazy(() => import('./pages/Swap'));
const LiquidityLocker = lazy(() => import('./pages/locker/LPLocker2024'));
const Listing = lazy(() => import('./pages/Listing'));
const SyntheticsPage = lazy(() => import('./pages/SyntheticsPage'));
const Tokenomics = lazy(() => import('./pages/token_tracker/Tokenomics'))

//DAOs
const DAOs = lazy(() => import('./pages/DAOs'));
const DAOsV2 = lazy(() => import('./pages/daosv2/DAOsV2'));
const DAOPageV2 = lazy(() => import('./pages/daosv2/DAOPageV2'));
const DAOPage = lazy(() => import('./pages/DAOPage'));
const Proposals = lazy(() => import('./pages/Proposals'));
const DAOForm = lazy(() => import("./pages/daosv2/DAOForm"));
const ProposalPageV2 = lazy(() => import('./pages/daosv2/ProposalPageV2'));
const EditDaoV2 = lazy(() => import('./pages/daosv2/EditDaoV2'));

//Utilities
const Powerup = lazy(() => import('./pages/utilities/Powerup'));
const PowerupFaq = lazy(() => import('./pages/utilities/PowerupFaq'))
const Royalty = lazy(() => import('./pages/Royalty'));

//Collections
const CollectionPage = lazy(() => import('./pages/drops/CollectionPage'))

//Ads
const Advertise = lazy(() => import('./components/Advertise'));

const App = () => {
    const {
        wharfSession,
        setWharfSession
    } = useStateContext();

    let session

    async function restoreSession() {
        session = await sessionKit.restore()
        setWharfSession(session)
      }

useEffect(() => {
    restoreSession()
}, [])

    return (
    <div>
        <GlobalStyle />
        <BrowserRouter>
            <MainWrapper>

            <div className={`min-h-screen w-full flex-2`}>

                    <Navbar2024 />
                    <NotificationModal />
                    <Body>
              
            <div>

                <Suspense fallback={<LoadingPage />}>
                <Routes>

                    {/* Home */}

                    <Route path="/" element={<HomePage2024 />} />
                    <Route path="/inventory" element={<WalletContextProvider> <WalletPage /> </WalletContextProvider> } />
                    <Route path="/wallet" element={<Wallet2024 />} />
                    <Route path="/asset/:AssetId" element={<AssetIdPage />} />

                    {/* User Profiles */}
                    <Route path="/u/:UserName" element={<UserPage />} />
                    
                    {/* Token Creator */}
                
                    <Route path="/token-creator" element={<TokenCreator />} />
                    <Route path="/wax-tokens" element={<TokenNav />} />
                    <Route path="/request-listing" element={<Listing />} />

                    <Route path="/whitelisting" element={<TokenCreator />} />
                    <Route path="/deploy-contract" element={<TokenCreator />} />
                    <Route path="/create-token" element={<TokenCreator />} />
                    <Route path="/issue-tokens" element={<TokenCreator />} />
                    <Route path="/transfer-tokens" element={<TokenCreator />} />
                    <Route path="/burn-tokens" element={<TokenCreator />} />

                    {/* Synthetics */}

                    <Route path="/synths" element={<SyntheticsPage />} />

                    {/* Farms */}

                    <Route path="/farms" element={<Farms2024 />} />
                    <Route path="/create-farm" element={<CreateFarm />} />
                    <Route path="/farm/:FarmName" element={<FarmPage />} />
                    <Route path="/edit-farm/:FarmName" element={<FarmOptions />} />
                    <Route path="/u/:CreatorName/farms" element={<UserFarms />} />
                    <Route path="/view-farm-nfts/:FarmUserCombo" element={<NFTsStakedByUserInFarm />} />
                    
                    {/* Farms V2 */}

                    <Route path="/v2/create-farm" element={<CreateFarmV2 />} />    
                    <Route path="/v2/edit-farm/:FarmName" element={<EditV2Farm />} /> 
                    <Route path="/v2/farm/:FarmName" element={<FarmPageV2 />} /> 
                    <Route path="/v2/stakers/:FarmName" element={<Stakers />} /> 
                    <Route path="/v2/farms" element={<Navigate to="/farms" />} />           
                    <Route path="/v2/farm-faq" element={<FarmFAQV2 />} />      


                    {/* Wojak NFTs */}
                    <Route path="/migrate" element={<Migrate />} />
                    <Route path="/swap" element={<SwapPage />} />
                    <Route path="/wojak" element={<Navigate to="/drops/247" />} />
                    <Route path="/wojak/drop" element={<Navigate to="/drops/247" />} />
                    <Route path="/wojak/swap" element={<Navigate to="/migrate" />} />
                    <Route path="/wojak/unpack" element={ <Navigate to="/unbox/246" />}  />

                    <Route path="/wojak/staking" element={<Navigate to="/farm/waxdao.1week" />} />
                    

                    {/* Pools */}

                    <Route path="/pools" element={<Pools />} />
                    <Route path="/create-pool" element={<CreatePool />} />
                    <Route path="/pool/:PoolName" element={<PoolPage />} />
                    <Route path="/edit-pool/:PoolName" element={<PoolOptions />} />

                    {/* Drops */}

                    <Route path="/my-drops" element={<MyDrops />} />
                    <Route path="/create-drop" element={<CreateDrop />} />
                    <Route path="/pack-tutorial" element={<PackTutorial />} />
                    <Route path="/create-premint" element={<CreatePremint />} />
                    <Route path="/create-pack" element={<MyPremintedPools />} />
                    <Route path="/create-pack/:PoolID" element={<CreatePack />} />
                    <Route path="/premint-pool/:PoolID" element={<PremintPage />} />
                    <Route path="/drops/:DropID" element={<DropPage />} />
                    <Route path="/collection/:CollectionName" element={<CollectionPage />} />
                    <Route path="/drops" element={<Drops />} />
                    <Route path="/unbox/:DropID" element={<UnboxPage />} />
                    <Route path="/c/:CollectionName/drops" element={<CollectionDrops />} />
                    <Route path="/edit-drop/:DropID" element={<EditDrop />} />
                    <Route path="/edit-pack/:DropID" element={<EditPack />} />

                    {/* Blends */}

                    <Route path="/blends" element={<Blends />} />
                    <Route path="/create-blend" element={<CreateBlend />} />
                    <Route path="/blends/:DropID" element={<BlendPage />} />
                    <Route path="/u/:CollectionName/blends" element={<CollectionBlends />} />
                    <Route path="/edit-blend/:DropID" element={<EditBlend />} />

                    {/* Escrow */}

                    <Route path="/escrow" element={<Escrow />} />
                    <Route path="/manage-escrow" element={<MyEscrow />} />

                    {/* Liquidity Locker */}

                    <Route path="/locker" element={<Locker />} />
                    <Route path="/manage-locks" element={<ManageLocks />} />
                    <Route path="/locks/:LockID" element={<LockPage />} />   
                    <Route path="/liquidity-locker" element={<LiquidityLocker />} /> 


                    

                    {/* Governance */}
                    
                    <Route path="/daos" element={<DAOs />} />
                    <Route path="/proposals/:ProposalID" element={<Proposals />} />                    
                    <Route path="/votes" element={<DAOs />} />
                    <Route path="/create-dao" element={<Navigate to="/v2/create-dao" />} />
                    <Route path="/daos/:DAOName" element={<DAOPage />} />

                    {/* DAOs v2 */}
                    <Route path="/v2/create-dao" element={<DAOForm />} />
                    <Route path="/v2/daos" element={<DAOsV2 />} />
                    <Route path="/v2/daos/:DAOName" element={<DAOPageV2 />} />
                    <Route path="/v2/edit-dao/:DAOName" element={<EditDaoV2 />} />
                    <Route path="/v2/:DAOName/proposals/:ProposalID" element={<ProposalPageV2 />} />

                    {/* Navigation */}

                    <Route path="/nft-farming" element={<FarmingNav />} />
                    <Route path="/wojak-nfts" element={<WojakNav />} />
                    <Route path="/token-creator" element={<TokenNav />} />                    
                    <Route path="/create" element={<Create />} />


                    {/* Advertising */}
                    <Route path="/ad-terms" element={<AdTerms />} />
                    <Route path="/advertise" element={<Advertise />} />

                    {/* Token Tracker */}
                    <Route path="/tokens" element={<AllTokens2024 />} />
                    <Route path="/tokens/:TokenID" element={<TokenPage />} />
                    <Route path="/tokenomics" element={<Tokenomics />} />
                    

                    {/* Royalty Contract */}
                    <Route path="/royalty-contract" element={<Royalty />} />

                    {/* Remove Contract */}
                    <Route path="/remove-contract" element={<RemoveContract />} />


                    <Route path="/waxlabs-report-1" element={<WaxLabs />} />
                    <Route path="/coding/resources" element={<WaxLabs2 />} />

                    <Route path="/guild-candidacy" element={<GuildCandidacy />} />
                    <Route path="/v2/dao-faq" element={<DAOFAQV2 />} />

                    <Route path="/settings" element={<Settings />} />
                    <Route path="/powerup" element={<Powerup />} />
                    <Route path="/powerup-faq" element={<PowerupFaq />} />

                    {/* 404 NOT FOUND CATCHALL */}

                    <Route path="*" element={<NotFound />} />

                </Routes>
                </Suspense>
            </div>
            </Body>
            <Footer />
            </div>

            </MainWrapper>       
        </BrowserRouter>
    </div>
  )
}

export default App