import React, { useState } from 'react'
import { SingleHighlightWrapper } from '../Styles2024'
import LoadingDiv from './LoadingDiv';
import { buildIpfsUrl } from '../data/functions/global_functions';
import LoadingSpinnerImageFallback from './LoadingSpinnerImageFallback';

const generateLink = (url) => {
    if(url.indexOf('http') == -1){
        return `https://${url}`
    }
    return url
}

const SingleHighlight = (props) => {
    const show = props.show;
    const hide = props.hide;
    const url = props.url;
    const image = props.image;
    const bannersAreLoading = props.bannersAreLoading;
    const [loading, setLoading] = useState(true);

  return (
    <SingleHighlightWrapper hide={hide} show={show}>
        {(loading || bannersAreLoading) && <LoadingSpinnerImageFallback top={'30px'} />}
        {!bannersAreLoading && url && image && (
            <a href={generateLink(url)} target="none">
            <img src={buildIpfsUrl(image, 500)} 
            onLoad={() => setLoading(false)}
            onError={() => setLoading(false)}
            loading="lazy" />
            </a>
        )}

  </SingleHighlightWrapper>
  )
}

export default SingleHighlight