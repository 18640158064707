import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  StakeLeftRow,
  StakeRightRow,
  StakersRow,
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
} from "../../components/LeftDiv";
import {
  getCurrentApiList,
  currentUsername,
  mainnetWebsiteURL,
} from "../../data/config";
import {
  CollectionProfileAvatarCont,
  CollectionProfileBGContainer,
  CollectionProfileContainer,
  MilestoneBoldWhite,
} from "../../data/css/CollectionPageStyles";
import collection_profile_stock_bg from "../../data/default_cover.png";
import {
  ExploreFiltersCont,
  ExploreFiltersInnerCont,
  FoldersContainer,
  FoldersRow,
  MainButton,
  MilestoneContainer,
  MilestoneDescription,
  NewListingBody,
  SingleFolderFourth,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import {
  changeLogo,
  depositTokens,
  extendPool,
  getHourlyReward,
  getPoolSize,
  getRewardToken,
  getTokenNameFromSymbol,
  getTokenToStake,
  getTotalStaked,
  setPoolDays,
} from "../../data/functions/pool_functions";
import {
  PoolLineBreak,
  SinglePoolDetail,
  SinglePoolDetailBold,
} from "../../data/css/PoolPageStyles";
import LoadingPage from "../../components/LoadingPage";
import { getAssetQuantity } from "../../data/functions/wallet_functions";
import { getRewardTokenBalance } from "../../data/functions/edit_farm_functions";
import { SendAllButton } from "../../data/css/WalletStyles";
import Error from "../../components/Error";
import { buildIpfsUrl } from "../../data/functions/global_functions";
import { STANDARD_NFT_IMAGE_WIDTH } from "../../data/constants";

const PoolOptions = ({ location }) => {
  const {
    farmData,
    setFarmData,
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    wharfSession
  } = useStateContext();

  const { PoolName } = useParams();

  const [profile, setProfile] = useState([]);
  const [poolDataIsLoading, setPoolDataIsLoading] = useState(true);
  const [stakersList, setStakersList] = useState([]);
  const [currentSection, setCurrentSection] = useState("Deposit");
  const [depositAmount, setDepositAmount] = useState(0);
  const [daysToAdd, setDaysToAdd] = useState(0);
  const [newLogo, setNewLogo] = useState("");
  const [minimumDays, setMinimumDays] = useState(0);
  const [rewardTokenBalance, setRewardTokenBalance] = useState("");

  useEffect(() => {
    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "pools",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        key_type: "name",
        indexName: "poolname",
        limit: 1,
        lower_bound: PoolName,
        upper_bound: PoolName,
        json: true,
      })
      .then((response) => {
        setFarmData(response.data.rows[0]);
      })
      .catch((error) => console.log(error))
      .finally(() => setPoolDataIsLoading(false));
  }, []);

  useEffect(() => {
    const abortCont5 = new AbortController();

    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "tokens",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        key_type: "name",
        index_position: "3",
        limit: 1000,
        lower_bound: PoolName,
        upper_bound: PoolName,
        json: true,
        signal: abortCont5,
      })
      .then((res) => {
        setStakersList(res.data.rows);
      })
      .catch((error) => console.log(error));

    return () => abortCont5.abort();
  }, []);

  useEffect(() => {
    if (farmData?.creator != null) {
      getRewardTokenBalance(farmData.contract, getTokenNameFromSymbol(farmData.rwdtoken), setRewardTokenBalance);
    }
  }, [farmData]);


  if (!poolDataIsLoading && farmData?.creator == currentUsername) {
    return (
      <div id="seo">
        <Helmet>
          <title>{PoolName} Details</title>
          <meta
            name="description"
            content={`Stake tokens in the ${PoolName} pool on WaxDao`}
          />
          <link rel="canonical" href={`${mainnetWebsiteURL}/pool/${PoolName}`} />
        </Helmet>

          <Modal className={enterModalDisplay}>
            <ModalContent>{enterModalText}</ModalContent>

            <Spinner style={{ display: loadingDisplay }}>
              <SpinnerRed />
              <SpinnerBlue />
              <SpinnerGreen />
            </Spinner>

            <br />
            <GameButton onClick={() => setEnterModalDisplay("hidden")}>
              Close Window
            </GameButton>
          </Modal>

          <CollectionProfileContainer>
            <CollectionProfileBGContainer>
              {profile?.image1 != null && profile?.image1?.length > 0 ? (
                <img
                  src={buildIpfsUrl(profile.image1, 900)}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={collection_profile_stock_bg}
                  style={{
                    width: "auto",
                    height: "auto",
                    minHeight: "100%",
                    minWidth: "100%",
                  }}
                />
              )}
            </CollectionProfileBGContainer>

            <CollectionProfileAvatarCont>
              <img
                src={
                  farmData.logo != null &&
                  buildIpfsUrl(farmData.logo, STANDARD_NFT_IMAGE_WIDTH)
                }
                style={{
                  width: "auto",
                  height: "auto",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              />
            </CollectionProfileAvatarCont>
          </CollectionProfileContainer>

          <div className="p-5 mt-4 text-white">
            <br />
            <span className="text-2xl font-semibold">{PoolName}</span>
            <br />
            <span className="text-md">
              {farmData.creator != null && <span>By {farmData.creator}</span>}
            </span>

            <MilestoneContainer>
              <MilestoneDescription>
                Stake{" "}
                <MilestoneBoldWhite>
                  {getTokenToStake(farmData)}
                </MilestoneBoldWhite>{" "}
                to earn{" "}
                <MilestoneBoldWhite>
                  {getRewardToken(farmData)}
                </MilestoneBoldWhite>
              </MilestoneDescription>
            </MilestoneContainer>
          </div>

          <NewListingBody>
            <ExploreFiltersCont>
              <h2>Pool Details</h2>

              <br />
              <ExploreFiltersInnerCont>
                <SinglePoolDetail>
                  Hourly Pool{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {getHourlyReward(farmData)}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Total Pool{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {getPoolSize(farmData)}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Minimum Stake{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {farmData.minamount} {getTokenToStake(farmData)}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Total Staked{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {getTotalStaked(farmData)}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Lock Period{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {farmData.mintime / 86400} Days
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Expires{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {new Date(farmData.enddate * 1000).toLocaleDateString()}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
              </ExploreFiltersInnerCont>
            </ExploreFiltersCont>

            <TallFiltersCont>
              <h2>Actions</h2>

              <br />
              <FoldersContainer>
                <FoldersRow>
                  <SingleFolderFourth
                    onClick={(e) => {
                      setCurrentSection("Deposit");
                    }}
                    selected={currentSection == "Deposit" && true}
                  >
                    Deposit
                  </SingleFolderFourth>
                  <SingleFolderFourth
                    onClick={(e) => {
                      setCurrentSection("Extend");
                    }}
                    selected={currentSection == "Extend" && true}
                  >
                    Extend
                  </SingleFolderFourth>
                  <SingleFolderFourth
                    onClick={(e) => {
                      setCurrentSection("Logo");
                    }}
                    selected={currentSection == "Logo" && true}
                  >
                    Logo
                  </SingleFolderFourth>
                  <SingleFolderFourth
                    onClick={(e) => {
                      setCurrentSection("Lock Time");
                    }}
                    selected={currentSection == "Lock Time" && true}
                  >
                    Lock Time
                  </SingleFolderFourth>
                </FoldersRow>
              </FoldersContainer>
              <br />
              <TallFiltersInnerCont>
                {currentSection == "Deposit" && (
                  <span>
                    <h3>Amount</h3>
                    <input
                      value={depositAmount}
                      onChange={(e) => setDepositAmount(e.target.value)}
                    />
                    <br/>
                    <SendAllButton
                      onClick={() => setDepositAmount(rewardTokenBalance)}
                    >
                      Deposit All {rewardTokenBalance}
                    </SendAllButton>
                    
                    <br/><br/>
                    <MainButton
                      onClick={() =>
                        depositTokens(
                          PoolName,
                          depositAmount,
                          farmData.contract,
                          farmData.rwdtoken,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        )
                      }
                    >
                      Deposit {depositAmount} {getRewardToken(farmData)}@{farmData?.contract}
                    </MainButton>
                  </span>
                )}

                {currentSection == "Extend" && (
                  <span>
                    <h3>Days To Add</h3>
                    <input
                      value={daysToAdd}
                      onChange={(e) => setDaysToAdd(e.target.value)}
                    />
                    <br/><br/>
                    <MainButton
                      onClick={() =>
                        extendPool(
                          PoolName,
                          daysToAdd,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        )
                      }
                    >
                      Extend Pool By {daysToAdd} Days
                    </MainButton>
                    <br/>
                  </span>
                )}

                {currentSection == "Logo" && (
                  <span>
                    <h3>IPFS Hash</h3>
                    <input
                      value={newLogo}
                      onChange={(e) => setNewLogo(e.target.value)}
                      placeholder={`e.g. Qmwaxdao123...`}
                    />
                    <br/><br/>
                    <MainButton
                      onClick={() =>
                        changeLogo(
                          PoolName,
                          newLogo,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        )
                      }
                    >
                      Set New Logo
                    </MainButton>
                    <br/>
                  </span>
                )}

                {currentSection == "Lock Time" && (
                  <span>
                    <h3>Minimum Staking Days</h3>
                    <input
                      value={minimumDays}
                      onChange={(e) => setMinimumDays(e.target.value)}
                      placeholder={`e.g. 3`}
                    />
                    <br/><br/>
                    <MainButton
                      onClick={() =>
                        setPoolDays(
                          PoolName,
                          minimumDays,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay,
                          wharfSession
                        )
                      }
                    >
                      Change Staking Period
                    </MainButton>
                    <br/>
                  </span>
                )}


              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Stakers List</h2>
            </TallFiltersCont>
            <br />
            <TallFiltersInnerCont>
              {stakersList.length > 0 &&
                stakersList
                  .sort(
                    (a, b) =>
                      getAssetQuantity(b.amountstaked) -
                      getAssetQuantity(a.amountstaked)
                  )
                  .map((item, index) => (
                    <StakersRow
                      key={index}
                      className={item.amountstaked == 0 && "hidden"}
                    >
                      <StakeLeftRow>{item.user}</StakeLeftRow>
                      <StakeRightRow>{item.amountstaked}</StakeRightRow>
                    </StakersRow>
                  ))}
            </TallFiltersInnerCont>
          </NewListingBody>
        <br />
        <br />
        <br />
      </div>
    );
  } else if (poolDataIsLoading) {
    return <LoadingPage />;
  } else if (!poolDataIsLoading && farmData?.creator != currentUsername) {
    return <Error error={"Only the pool creator can access this page"} />;
  }
};

export default PoolOptions;
